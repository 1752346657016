import React from 'react';

import { FacebookIcon, InstagramIcon, WebsiteIcon } from '../../assets/images/decathlon';
import { LinkedInIcon } from '../../assets/images/social';

import { $Flexbox } from '../../css/styles';

export const BeautynBeyondSocial = () => (
  <$Flexbox width="auto" justify="space-around" padding="16px 16px">
    <a href="https://www.facebook.com/Beauty-Beyond-105424905537994" target="_blank">
      <FacebookIcon />
    </a>
    <a href="https://www.instagram.com/beautyandbeyondindia/" target="_blank">
      <InstagramIcon />
    </a>
    <a href="https://www.beautyandbeyond.in" target="_blank">
      <WebsiteIcon />
    </a>
    <a href="https://www.linkedin.com/company/skandhanshi-retail-private-limited/" target="_blank">
      <LinkedInIcon />
    </a>
  </$Flexbox>
);

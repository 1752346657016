import { CoralogixRum } from '@coralogix/browser';
import { config } from './config';

if (!config?.isDev)
  CoralogixRum.init({
    public_key: config.coralogix.public_key,
    application: config.coralogix.application,
    version: '1.0',
    coralogixDomain: 'AP1',
  });

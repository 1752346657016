export const campaignTypes = {
  bannerInbill: 'bannerInBill',
  surveyInBill: 'surveyInBill',
  adBelowBill: 'adBelowBill',
  sellBelowBill: 'sellBelowBill',
  popupOverBill: 'popupOverBill',
};

export const getCampaignMaxHeight = (type) => {
  switch (type) {
    case campaignTypes.bannerInbill:
      return '100%';
    //return "200px"

    case campaignTypes.adBelowBill:
      return '750px';
  }
};

import React from 'react';

import { FacebookIcon, InstagramIcon, WebsiteIcon } from '../../assets/images/decathlon';

import { $Flexbox } from '../../css/styles';

export const InventisSocial = () => (
  <$Flexbox width="auto" justify="space-around" padding="16px 16px">
    <a href="https://www.facebook.com/aomidirect/" target="_blank">
      <FacebookIcon />
    </a>
    <a href="https://www.instagram.com/aomidirect/" target="_blank">
      <InstagramIcon />
    </a>
    <a href="https://aomi.in/" target="_blank">
      <WebsiteIcon />
    </a>
  </$Flexbox>
);

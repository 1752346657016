import React from 'react';

import {
  WebIconofConnplex,
  InstaIconofConnplex,
  FacebookIconofConnplex,
  LinkedinIconofConnplex,
  TwitterIconofConnplex,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const ConnplexSocial = () => (
  <$Flexbox className="Socialalign" justify="space-around" padding="16px 0px" alignItems="center">
    <a href="https://www.theconnplex.com/" target="_blank" rel="noopener noreferrer">
      <WebIconofConnplex style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.instagram.com/theconnplex/" target="_blank" rel="noopener noreferrer">
      <InstaIconofConnplex style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.facebook.com/connplex" target="_blank" rel="noopener noreferrer">
      <FacebookIconofConnplex style={{ width: '30px', height: '30px' }} />
    </a>
    <a
      href="https://www.linkedin.com/company/the-connplex"
      target="_blank"
      rel="noopener noreferrer">
      <LinkedinIconofConnplex style={{ width: '33px', height: '30px' }} />
    </a>
    <a href="https://x.com/CONNPLEX1?s=20" target="_blank" rel="noopener noreferrer">
      <TwitterIconofConnplex style={{ width: '33px', height: '30px' }} />
    </a>
  </$Flexbox>
);

import React from 'react';

import {
  FacebookIconofKaya,
  InstagramIconofKaya,
  TwitterIconofKaya,
  WebsiteIconofKaya,
  WhatsappIconofKaya,
  YoutubeIconofKaya,
  CallUsIconofKaya,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const KayaSocial = () => (
  <React.Fragment>
    {/* <SocialText> Follow us on Social Media <br/>Get 10% Discount on F&B items</SocialText> */}
    <$Flexbox className="Socialalign" justify="space-around" padding="16px 0px" alignItems="center">
      <a href="https://www.facebook.com/KayaClinicIndia" target="_blank" rel="noopener noreferrer">
        <FacebookIconofKaya style={{ width: '30px', height: '30px' }} />
      </a>
      <a href="https://www.kaya.in/" target="_blank" rel="noopener noreferrer">
        <WebsiteIconofKaya style={{ width: '33px', height: '30px' }} />
      </a>
      <a href="https://www.youtube.com/@kayaclinics" target="_blank" rel="noopener noreferrer">
        <YoutubeIconofKaya style={{ width: '33px', height: '30px' }} />
      </a>
      <a
        href="https://www.instagram.com/kayaskinclinics/"
        target="_blank"
        rel="noopener noreferrer">
        <InstagramIconofKaya style={{ width: '30px', height: '30px' }} />
      </a>
      <a
        href="https://api.whatsapp.com/send/?phone=%2B918550042000&text=Hi%21&type=phone_number&app_absent=0"
        target="_blank"
        rel="noopener noreferrer">
        <WhatsappIconofKaya style={{ width: '33px', height: '30px' }} />
      </a>
      <a href="tel:+18002095292" target="_blank" rel="noopener noreferrer">
        <CallUsIconofKaya style={{ width: '33px', height: '30px' }} />
      </a>
    </$Flexbox>
  </React.Fragment>
);

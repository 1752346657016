import React from 'react';

import {
  InstaIconOfRzppos,
  LinkedinIconofRzppos,
  TwitterIconofRzppos,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const RzpPosSocial = () => (
  <$Flexbox
    className="Socialalign"
    justify="space-around"
    padding="10px !important"
    alignItems="center">
    <a href="https://www.linkedin.com/company/razorpay" target="_blank" rel="noopener noreferrer">
      <LinkedinIconofRzppos style={{ width: '30px', height: '30px' }} />
    </a>
    <a
      href="https://www.instagram.com/razorpay/?igsh=aGpoaWd1dTZrOXM0"
      target="_blank"
      rel="noopener noreferrer">
      <InstaIconOfRzppos style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://twitter.com/razorpay" target="_blank" rel="noopener noreferrer">
      <TwitterIconofRzppos style={{ width: '30px', height: '30px' }} />
    </a>
  </$Flexbox>
);

import React, { useEffect } from 'react';
const AdsComponent = (props) => {
  const {
    adSlot,
    adClient = 'ca-pub-6122583428251315',
    height = '200px',
    width = '420px',
    display = 'inline-block',
  } = props;
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Error in adsbygoogle push', e.message);
    }
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display, width, height, margin: 'auto' }}
        data-ad-client={adClient}
        data-ad-slot={adSlot}></ins>
    </>
  );
};

export default AdsComponent;

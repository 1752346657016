import React from 'react';
import {
  FBIcon,
  InstaIcon,
  PhoneIcon,
  WebIcon,
  WhatsAppIcon,
  YoutubeIcon,
  MarcaTwitterIcon,
} from '../../assets/images/social';
import { $Flexbox, $SocialLink } from '../../css/styles';
import { centroSocial, centroGrandeSocial, billMeDemoSocials } from '../billView/data';

const top = [
  {
    grandeLink: centroGrandeSocial.fb,
    link: centroSocial.fb,
    component: <FBIcon />,
  },
  {
    link: centroSocial.insta,
    grandeLink: centroGrandeSocial.insta,
    component: <InstaIcon />,
  },
  {
    link: centroSocial.youtube,
    grandeLink: centroGrandeSocial.youtube,
    component: <YoutubeIcon />,
  },
];

export const SocialTop = ({ isCentroGrande }) => {
  return (
    <$Flexbox padding="16px 16px 0" justify="space-around" alignItems="center">
      {top.map((social) => (
        <$SocialLink
          target="_blank"
          rel="noopener"
          href={isCentroGrande && social.grandeLink ? social.grandeLink : social.link}>
          {social.component}
        </$SocialLink>
      ))}
    </$Flexbox>
  );
};

const bottom = [
  {
    link: centroSocial.phone,
    grandeLink: centroGrandeSocial.phone,
    component: <PhoneIcon />,
    text: 'Call us',
  },
  {
    link: centroSocial.whatsapp,
    grandeLink: centroGrandeSocial.whatsapp,
    component: <WhatsAppIcon />,
    text: 'Chat on WhatsApp',
  },
  {
    link: centroSocial.website,
    component: <WebIcon />,
    text: 'Website',
  },
];

export const SocialBottom = ({ isCentroGrande }) => {
  return (
    <$Flexbox justify="space-around" alignItems="center" padding={'16px 0px'}>
      {bottom.map((social) => (
        <$SocialLink
          target="_blank"
          rel="noopener"
          href={isCentroGrande && social.grandeLink ? social.grandeLink : social.link}>
          {social.component}
          <p>{social.text}</p>
        </$SocialLink>
      ))}
    </$Flexbox>
  );
};

const billmeDemo = (storeId) => [
  {
    link: billMeDemoSocials[storeId].fb,
    component: <FBIcon />,
  },
  {
    link: billMeDemoSocials[storeId].insta,
    component: <InstaIcon />,
  },
  {
    link: billMeDemoSocials[storeId].youtube,
    component: <YoutubeIcon />,
  },
  {
    link: billMeDemoSocials[storeId].twitter,
    component: <MarcaTwitterIcon />,
  },

  {
    link: billMeDemoSocials[storeId].twitter,
    component: <WebIcon />,
  },
];

export const SocialBillMeBrands = ({ storeId }) => {
  return (
    <$Flexbox padding={'16px 0px'} justify="space-around" alignItems="center">
      {billmeDemo(storeId).map((social) => (
        <$SocialLink target="_blank" rel="noopener" href={social.link} style={{ minWidth: 20 }}>
          {social.component}
        </$SocialLink>
      ))}
    </$Flexbox>
  );
};

export const F6Socials = ({ isF6FashionWomen }) => {
  const socials = [
    {
      link: 'https://www.facebook.com/F6FASHIONSTORE',
      component: <FBIcon />,
    },
    {
      link: isF6FashionWomen
        ? 'https://www.instagram.com/f6womensfashion/'
        : 'https://www.instagram.com/f6fashionstore/',
      component: <InstaIcon />,
    },
    {
      link: 'https://f6fashion.com/',
      component: <WebIcon />,
    },
  ];
  return (
    <$Flexbox padding={'16px 0px'} justify="space-around" alignItems="center">
      {socials.map((social) => (
        <$SocialLink target="_blank" rel="noopener" href={social.link} style={{ minWidth: 20 }}>
          {social.component}
        </$SocialLink>
      ))}
    </$Flexbox>
  );
};

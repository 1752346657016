import styled from 'styled-components';

export const MainHeader = styled.h2`
  margin: 1em 0px 0px;
  font-weight: 400;
  /* font-family: 'Roboto', sans-serif; */
  font-size: 16px;
`;

/* export const SubHeader = styled.p`
    text-align: center; 
    font-size: 0.8em; 
    color: hsl( 0, 0%, 45%);
    margin: 0.5em 0px 1em;
    font-family: 'Roboto', sans-serif;
` */

export const Option = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  font-size: 1.2em;
  position: relative;
  background-color: ${(props) => (props.selected ? 'hsl(203, 75%, 97%)' : '')};
  background-image: linear-gradient(to right, #f2f2f2 70%, rgba(255, 255, 255, 0) 20%);
  background-position: bottom;
  background-size: 13px 1px;
  background-repeat: repeat-x;
  margin-top: 5px;
  border-radius: 8px;
  /* font-family: 'Roboto', sans-serif; */

  @media screen and (min-width: 767px) {
    font-size: 0.8em;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  margin: ${(props) => props.margin || '0px'};
  opacity: ${(props) => (props.transparent ? '0' : '1')};
`;

export const CheckboxInput = styled(Input)`
  position: ${(props) => props.position || 'static'};
`;

export const Box = styled.div`
  display: block;
  width: ${(props) =>
    ({
      tiny: '5px',
      small: '10px',
      medium: '20px',
      large: '30px',
      custom: props.customSize,
    })[props.size]};
  height: ${(props) =>
    ({
      tiny: '5px',
      small: '10px',
      medium: '20px',
      large: '30px',
      custom: props.customSize,
    })[props.size]};
  border-radius: ${(props) => props.borderRadius || '0px'};
  border: ${(props) => props.border || 'none'};
  position: ${(props) => props.position || 'static'};
  top: ${(props) => props.top || ''};
  right: ${(props) => props.right || ''};
  bottom: ${(props) => props.bottom || ''};
  left: ${(props) => props.left || ''};
`;

import React from 'react';

import {
  MarcaFacebookIcon,
  MarcaInstagramIcon,
  Le15WebIcon,
  MarcaTwitterIcon,
  LinkedInIcon,
} from '../../assets/images/social';

import { WebsiteIcon } from '../../assets/images/decathlon';
import { $Flexbox } from '../../css/styles';

export const MovieMaxIconsSet = () => (
  <$Flexbox justify="space-between" width="auto">
    <$Flexbox
      as="a"
      target="_blank"
      href="https://www.instagram.com/moviemaxofficial"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaInstagramIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Instagram</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://www.facebook.com/moviemaxoffl"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaFacebookIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Facebook</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://twitter.com/moviemaxoffl"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaTwitterIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Twitter</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://www.linkedin.com/company/moviemax"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <LinkedInIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>LinkedIn</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://www.moviemax.co.in"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <WebsiteIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Website</p>
    </$Flexbox>
  </$Flexbox>
);

import React from 'react';

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WebsiteIcon,
} from '../../assets/images/decathlon';

import { $Flexbox } from '../../css/styles';

export const ValueSocial = () => (
  <$Flexbox width="auto" justify="space-between" padding="16px 32px">
    <a href="https://www.facebook.com/valueplusindia" target="_blank">
      <FacebookIcon />
    </a>
    <a href="https://www.instagram.com/valueplusindia" target="_blank">
      <InstagramIcon />
    </a>
    <a href="https://valueplusretail.com" target="_blank">
      <WebsiteIcon />
    </a>
    <a href="https://twitter.com/Valueplusindia1" target="_blank">
      <TwitterIcon />
    </a>
  </$Flexbox>
);

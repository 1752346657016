import React from 'react';

import {
  MarcaFacebookIcon,
  MarcaInstagramIcon,
  Le15WebIcon,
  PinterestIcon,
  YoutubeIcon,
  MeNMomswebsiteIcon,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const MenMomsIconsSet = () => (
  <$Flexbox justify="space-between" width="auto">
    <$Flexbox
      as="a"
      href="https://www.instagram.com/menmoms.india"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaInstagramIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Instagram</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://www.facebook.com/MeNMomsStore"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaFacebookIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Facebook</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://www.youtube.com/channel/UChmuAOPHOQKh833nihOPnZA"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <YoutubeIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Youtube</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://in.pinterest.com/meemeeindia"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <PinterestIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Pinterest</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://www.menmoms.in/"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MeNMomswebsiteIcon style={{ width: '30px', height: '30px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Website</p>
    </$Flexbox>
  </$Flexbox>
);

export const Le15Online = () => <$Flexbox justify="space-around"></$Flexbox>;

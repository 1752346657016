/* Send Analytics to Analytics Service */ /* 
export const pushAnalytics = (params) => {
    window.clevertap && window.clevertap.event.push(params);
} */

import { useEffect } from 'react';

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const useDebouncedEffect = (effect, deps = [], delay = 750) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
  }, [...deps, delay]);
};

export { customFrontendId } from './custom-frontend-id';

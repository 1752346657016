import React from 'react';

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WebsiteIcon,
  YoutubeIcon,
} from '../../assets/images/decathlon';

import { $Flexbox } from '../../css/styles';

export const HaltSocial = () => (
  <$Flexbox width="auto" justify="space-between" padding="16px 16px">
    <a href="https://www.facebook.com/HALTNutrition/" target="_blank">
      <FacebookIcon />
    </a>
    <a href="https://www.instagram.com/haltnutrition/" target="_blank">
      <InstagramIcon />
    </a>
    <a href="https://www.halt.in/" target="_blank">
      <WebsiteIcon />
    </a>
    <a href="https://twitter.com/halt_n?lang=en" target="_blank">
      <TwitterIcon />
    </a>
    <a href="https://youtube.com/channel/UCnUyro3BChYk92SN1vIMlQQ" target="_blank">
      <YoutubeIcon />
    </a>
  </$Flexbox>
);

import React from 'react';
import { FormattedNumber } from 'react-intl';

import { Capitalize } from '../css/styles/common';

export const renderAmount = (value = 0, style = 'currency', fractionDigits, currencyCode) => {
  const currency = style === 'currency' ? { currency: currencyCode || 'INR' } : {};
  let amount = value;
  if (style === 'percent' && value) {
    amount = isNaN(value / 100) ? 0 : value / 100;
  }

  if (String(value).includes(',')) {
    const finalAmount = String(value).replace(',', '');
    amount = Number(finalAmount);
  }

  if (value || value >= 0)
    return (
      <FormattedNumber
        style={style}
        value={amount}
        {...currency}
        {...(!!fractionDigits
          ? {
              minimumFractionDigits: fractionDigits,
              maximumFractionDigits: fractionDigits,
            }
          : {})}
      />
    );

  return null;
};

export const renderDecimal = (value = 0) => {
  let amount = value;

  if (String(value).includes(',')) {
    const finalAmount = String(value).replace(',', '');
    amount = Number(finalAmount);
  }

  if (value || value >= 0) return Number(amount)?.toFixed(2);

  return null;
};

export const getPaymentMode = (mode) => {
  switch (mode) {
    case 'nb':
      return 'Net Banking';

    case 'cc':
      return 'Credit Card';

    case 'dc':
      return 'Debit Card';

    case 'upi':
      return 'UPI';

    default:
      return <Capitalize>{mode}</Capitalize>;
  }
};

import styled, { keyframes } from 'styled-components';
import HeaderBg from '../../assets/images/graphics.svg';

function getBg(props) {
  if (props.isBK) return 'none';
  else return HeaderBg;
}

const Spin = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const Spin1 = keyframes`
0% {
          transform: rotate(0deg);
        }

        30% {
          transform: rotate(0deg);
        }

        70% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
`;
const Spin2 = keyframes`
0% {
          transform: rotate(0deg);
        }

        30% {
          transform: rotate(270deg);
        }

        70% {
          transform: rotate(270deg);
        }

        100% {
          transform: rotate(360deg);
        }
`;
const Spin3 = keyframes`
0% {
          transform: rotate(0deg);
        }

        30% {
          transform: rotate(180deg);
        }

        70% {
          transform: rotate(180deg);
        }

        100% {
          transform: rotate(360deg);
        }
`;
const Spin4 = keyframes`
    0% {
        transform: rotate(0deg);
    }

    30% {
        transform: rotate(90deg);
    }

    70% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

export const StyledHeader = styled.div`
  background: ${(props) => (props.isBK ? `none` : '#38a1e3')};
  height: 200px;
  position: relative;
  overflow: hidden;

  .header-bg {
    background: url(${(props) => getBg(props)}) no-repeat;
    height: 100%;
    width: 100%;
    padding-top: 90px;
    background-size: 100%;
  }
`;

export const $CinepolisMexicoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  width: 88%;
  margin: 0 6% 0 6%;

  div {
    display: flex;
    align-items: center;
  }

  a {
    margin-left: 18px;
  }
`;

export const LoaderText = styled.p`
  font-size: 16px;
  color: #043040;
  text-align: center;
  font-weight: 500;
  margin: 10px 0px;
  font-family: 'Inter', sans-serif;
`;

export const LoaderContainer = styled.div`
  text-align: center;
  height: 100vh;
`;

export const LoaderItem = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  &:before {
    display: block;
    width: 48%;
    height: 48%;
    border-radius: 0 40% 0 40%;
    background-color: #12b9f3;
    content: '';
  }

  &.item--1 {
    animation: ${Spin1} 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &.item--2 {
    animation: ${Spin2} 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &.item--3 {
    animation: ${Spin3} 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &.item--4 {
    animation: ${Spin4} 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
`;

export const BillMaxWidth = '420px';

export const NyCinemas = 'NyCinemas';

export const VENDOR_META = {
  [NyCinemas]: {
    tnc: [
      'Tickets once purchased are non-refundable and are not available for exchange and cancellation.',
      'A convenience fee per ticket is levied on all tickets booked online.',
      'Tickets purchased for cinema is valid for that cinema only.',
      'To counter unforeseen delays, tickets should be collected half an hour before show time.',
      'Show timings are subject to change.',
      'Ticket is compulsory for children of 3 years & above.',
      'Patrons below the age of 18 years cannot be admitted for movies certified A.',
      'Outside food & beverages will not be allowed inside the Cinema premises.',
      'Food orders once placed cannot be cancelled.',
      'No replacement/compensation is permissible on loss of ticket. Mutilated or defaced ticket will not be accepted.',
      'Smoking, drinking alcohol and chewing of pan is strictly prohibited inside the cinema premises.',
      'Patrons under the influence of alcohol or any other form of drugs will not be allowed inside the cinema premises.',
      'Items like carry bags, eatables, helmets, handbags are not allowed inside the cinema premises and are strictly prohibited.',
      'Items like laptops, camera, knives, lighter, match box, cigarettes, firearms, and all types of inflammables, unsafe or hazardous objects are strictly prohibited.',
      'Baggage counter facility for patrons is not available at the cinema premises, inconvenience caused is deeply regretted.',
      'The management will not be responsible for the loss or damage of any personal property.',
      'The 3D glasses will be available at the cinema for 3D films and must be returned by patrons before exiting the cinema premises. For 3D movies, ticket price includes charges towards usage of 3D glasses and is non-refundable.',
      'Decision(s) taken by NY Cinemas shall be final and binding, ``rights of admission reserved``.',
      'Terms and Conditions are subject to change.',
    ],
  },
};

import RzpLogoMark from '../assets/images/logos/RP-LOGOMARK.svg';
import styled from 'styled-components';
import { $Container } from './templates/styles';
const RZP_BG_COLOR = '#11274E';

const CenteredImg = styled.img`
  position: absolute;
  transform: rotate(32deg);
  z-index: 999;
  left: 15%;
`;

const $Pillar = styled.div`
  position: absolute;
  content: '';
  width: 100%;
  animation: load 1.2s ease-in;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  transform: skewY(-32deg);
  height: 0px;
  background-color: white;

  @keyframes load {
    from {
      background-color: ${RZP_BG_COLOR};
    }
    to {
      height: 1000px;
      background-color: ${RZP_BG_COLOR};
    }
  }
`;

const $SlantedLine = styled.div`
  height: 22vh;
  transform: rotate(-32deg);
  transform-origin: bottom left;
  position: absolute;
  top: 58%;
  z-index: 999;
  width: 1000px;
  background-color: white;
`;

const $Line = styled.div`
  position: absolute;
  background-color: rgb(17, 39, 78);
  height: 1px;
  z-index: 1007;
  animation: expand-width 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  width: 100px;
  @keyframes expand-width {
    from {
      width: 100px;
    }
    to {
      width: 1000px;
    }
  }
`;

const AnimatedImg = styled(CenteredImg)`
  animation-fill-mode: forwards;
  @keyframes opac {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation-delay: 1s;
  animation: 1s opac;
`;

const RzpLoader = () => {
  return (
    <$Container style={{ height: '100vh' }}>
      <$Pillar
        style={{
          transform: 'scale(-1) skewY(-32deg)',
          position: 'absolute',
          bottom: '50%',
        }}
      />
      <$SlantedLine>
        <$Line style={{ right: 0, transform: 'translateX(-398px)' }} />
        <$Line style={{ bottom: 0 }} />
        <AnimatedImg style={{ height: '20vh' }} src={RzpLogoMark} />
      </$SlantedLine>
      <$Pillar style={{ top: '50%' }} />
    </$Container>
  );
};

export default RzpLoader;

import React from 'react';
import { FBIcon, InstaIcon } from '../../assets/images/social';
import { $Flexbox, $SocialLink } from '../../css/styles';
import { joshhRestraunts } from '../billView/data';

const top = [
  {
    link: joshhRestraunts.fb,
    component: <FBIcon />,
  },
  {
    link: joshhRestraunts.insta,
    component: <InstaIcon />,
  },
];
export const JoshhSocialTop = () => {
  return (
    <$Flexbox width={'auto'} padding="16px 16px 0" justify="space-around" alignItems="center">
      {top.map((social) => (
        <$SocialLink target="_blank" rel="noopener" href={social.link}>
          {social.component}
        </$SocialLink>
      ))}
    </$Flexbox>
  );
};

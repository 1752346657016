// Color name credit - http://chir.ag/projects/name-that-color

export const theme = {
  color: {
    aquaHaze: '#F2F5F8',
    greenPea: '#2A6C44',
    mineShaft: '#313131',
    white: '#FFF',
    earlyDawn: '#FFF7E5',
    dandelion: '#FFDC64',
    doveGray: '#696969',
    success: '#62B349',
    malachite: '#00BE18',
    primary: '#3B86FF',
    light: '#BFBFBF',
    transparent: 'transparent',
    denim: '#1482C2',
    toast: {
      success: {
        color: '#155724',
        background: '#d4edda',
      },
      warning: {
        color: '#856404',
        background: '#fff3cd',
      },
      error: {
        color: '#721c24',
        background: '#f8d7da',
      },
    },
    nykaa: '#fc2779',
    kaya: '#b17358',
  },
  spacing: {
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '20px',
    xxl: '24px',
    x3l: '28px',
    x4l: '32px',
  },
};

import React from 'react';
import styled from 'styled-components';
import { VENDOR_META } from 'components/constants';
import { ReactComponent as InstagramBW } from 'assets/images/instagram-logo-bw.svg';
import { Dash } from '../../css/styles';

const StyledUl = styled.ul`
  font-family: 'Roboto';
  font-size: 13px;
  margin: 6px 5px -10px -10px;
  line-height: 1.40;
}`;
const StyledOl = styled.ol`
  font-family: 'Roboto';
  font-size: 13px;
  margin: 6px 5px -10px -10px;
  line-height: 1.4;
`;

const StyledLi = styled.li`
  margin-bottom: 15px;
`;
const SuperKP = styled.p`
  text-decoration: none;
  font-weight: 550;
  font-size: 17px;
`;
const RelaxoD = styled.div`
  width: 100%;
`;
const PTag = styled.p`
  text-align: center;
`;
const ZudioDiv = styled.div`
  text-align: center;
`;
const GoColorstag = styled.div`
  text-align: center;
`;

const StyledButton = styled.a`
  display: inline-block;
  text-align: center;
  width: 250px;
  padding: 15px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-transform: capitalize;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;

const ReturnPolicyButton = styled(StyledButton)`
  background-color: #E5F4FF;
  border 2px solid  #A6D8FD;
  color: #000; 
  text-decoration: underline;
  font-size: 12px;
  padding: 30px 10px;
  font-weight:600;
`;

const OfferPolicyButton = styled(StyledButton)`
 background-color: #F9DDE1;
  border 2px solid  #FA9CAC;
  color: #000; 
  text-decoration: underline;
  font-size: 12px;
    padding: 30px 10px;
    font-weight:600;
`;

const ZudioPTag = styled.p`
  font-weight: 800;
  font-style: italic;
  font-size: 22px;
`;
const ZudioDivTag = styled.div`
  font-weight: 800;
  font-style: italic;
  font-size: 22px;
`;
const ZudioDivTagCust = styled.div`
  font-weight: 800;
  font-style: italic;
  font-size: 16px;
`;

const TnCStaticContent = (props) => {
  const {
    parsedData,
    brandName,
    brandLogo,
    multiCoupons,
    isManyavar,
    isSuperK,
    isKaya,
    isCinepolisGroup,
    isConnplex,
    isRoongta,
    isMovieMaxTicket,
    isRelaxo,
    isSamoh,
    isZudio,
    isBewakoof,
    isMiraj,
    isNyCinemas,
    isHouseofFett,
    isGoColors,
  } = props;

  const getFoodVoucherTnc = () => {
    return parsedData?.customData.ticketType === 'CINEPOLIS_FOOD' || multiCoupons ? (
      <>
        <li>Food voucher can be redeemed for any food/ beverage</li>
        <li>Food Voucher is valid until 4 hours after the show start</li>
        <li>Food voucher cannot be combined with any other offer</li>
        <li>Food Voucher is valid only at PopCorner (Cafe) Counter for redemption</li>
        <li>
          Food Voucher Coupon Code needs to be presented to Miraj Staff at PopCorner (Cafe) Counter
          for redemption
        </li>
      </>
    ) : null;
  };

  const getMirajMovieTnC = () => {
    return parsedData?.customData.ticketType !== 'CINEPOLIS_FOOD' ? (
      <>
        <li>Outside Food and Beverage is not allowed inside the cinema premises.</li>
        <li>Ticket required for child 3 years and above.</li>
        <li>
          Ticket for ``A`` rated movie should not be purchased for people under 18 years of age.
          There won`t be a refund for tickets booked in such cases.
        </li>
        <li>
          Ticket once purchased cannot be exchanged or adjusted/transferred for any other show.
        </li>
        <li>
          Handbags, Laptops, Tabs, cameras and all other electronic items are not allowed inside
          cinema premises.
        </li>
        <li>
          Smoking is strictly not permitted inside the cinema premises. Cigarettes, lighters,
          matchsticks, Gutkha, Pan masala etc. will not be allowed.
        </li>
        <li>
          People under the influence of Alcohol and Drugs will not be allowed inside the cinema
          premises.
        </li>
        <li>
          Items like laptop, cameras, knives, lighter, match box , cigarettes, firearms and all
          types of inflammable objects are strictly prohibited.
        </li>
        <li>
          Items like carry-bags eatables , helmets , handbags are not allowed inside the theaters
          and are strictly prohibited.
        </li>
        <li>For 3D movies, ticket price includes charges towards usage of 3D glasses.</li>
        <li>Incase the ticket is lost or misplaced, duplicate ticket will not be issued.</li>
        <li>Cinema Reserves the Right of Admission.</li>
        <li>Decision(s) taken by Miraj management is final & abiding.</li>
      </>
    ) : null;
  };

  if (isSuperK) {
    return <SuperKP>This is a computer generated invoice. No signature is required.</SuperKP>;
  } else if (isHouseofFett) {
    return (
      <>
        <p>TERMS & CONDITIONS</p>
        <ol>
          <li>Goods one sold will not be refunded.</li>
          <li>
            Goods sold can be exchanged within a period of 15 days,if they are not,washed,altered
            and are in a saleable condition along with proper tags.
          </li>
          <li>Goods billed with this retailer can only be exchanged with the same.</li>
          <li>The original receipt should be presented for exchange.</li>
          <li>Goods made on special order/Customised / Altered cannot be exchanged.</li>
          <li>Sale items will not be exchanged/refunded.</li>
          <li> House of Fett is a trademark of Fett Brands Pvt. Ltd</li>
          <li>
            {' '}
            "Any and All queries / Customer complaints shall be directed towards &nbsp;
            <a href="mailto:sales@houseoffett.com" target="_blank" rel="noopener noreferrer">
              sales@houseoffett.com
            </a>
            "{' '}
          </li>
        </ol>
      </>
    );
  } else if (isKaya) {
    return (
      <StyledOl>
        <StyledLi>
          {' '}
          The Client agrees that the Services are booked with free consent of the Client. The Client
          is informed and understands that the clinical results of Services vary from person to
          person; or depends upon the regularity and frequency in attending the prescribed services;
          following up as advised; or prompt follow up in case of any adverse outcome; individual’s
          health condition including allergies, medical history, skin type and response of body;
          self-care and precautions; compliance of instructions given by Doctor at Kaya and
          therefore Client shall not raise any claim against Kaya including for results or adverse
          events.
        </StyledLi>
        <StyledLi>
          {' '}
          Refunds are not allowed on Services & Products. Exchange in case of defective product
          should be done within 14 days of purchase with a valid copy of the purchase invoice at the
          clinic of purchase.
        </StyledLi>
        <StyledLi>
          {' '}
          Services have to be utilized strictly within the package expiry period.{' '}
        </StyledLi>
        <StyledLi>
          {' '}
          Balance payment of the services is payable on or before the consumption/scheduled payment
          of the client’s pending sessions.
        </StyledLi>
        <StyledLi>
          {' '}
          Kaya Clinic reserves the right to alter/modify any terms and conditions at any point of
          time without any reason or intimation whatsoever.
        </StyledLi>
        <StyledLi>
          {' '}
          In case of any dispute, the same shall be subject to Mumbai jurisdiction.
        </StyledLi>
        <StyledLi>
          {' '}
          For future reference retain your invoice/advance receipt /proforma invoice.
        </StyledLi>
        <StyledLi>
          E-Consultation T&C Please click here{' '}
          <a target="_blank" href="https://bit.ly/2AEtEGX">
            {' '}
            https://bit.ly/2AEtEGX{' '}
          </a>
          .
        </StyledLi>
      </StyledOl>
    );
  } else if (isCinepolisGroup) {
    return (
      <>
        <section className="service-note">
          <h4>COVID-19 Safety Rules:</h4>

          <ul>
            <li>Masks are always mandatory in the cinemas unless eating.</li>
            <li>Social distancing will be maintained between seats.</li>
            <li>You are advised to show the Aarogya Setu App at the cinema entrance.</li>
            <li>Temperature Screening will be performed at the entrance.</li>
            <li>Sanitizers are available for patrons.</li>
          </ul>
        </section>
        <section className="service-note">
          <h4>Rules and Regulations:</h4>

          <ul>
            <li>
              Following Items Are Strictly Prohibited : Handbags, Carry Bags, Mobile Charger,
              Electronic items, Helmets, Bottles, Tiffin Box, Outside Eatables (Including Guthka,
              Pan-masala),Outside Beverages, Chewing Gum, Lighter, Match Box, Cigarette, Firearms &
              All kind of inflammable objects etc
            </li>
            <li>
              Pricing of the tickets as shown against the show-timing are all inclusive admission
              prices.
            </li>
            <li>
              A non-refundable 3D glass usage fee of Rs. 30 per ticket would be charged per ticket
              for all 3D shows.
            </li>
            <li>The Cinema Management reserves the rights of admission.</li>
            <li>
              For ‘A’ rated content, patrons below 18 years of age will not be allowed to enter.
            </li>
            <li>Please go through the emergency evacuation plan of the cinema & the auditorium.</li>
            <li>Tickets once sold are non-transferable & non-refundable.</li>
            <li>Please check detail on the ticket & your balance before leaving the counter.</li>
            <li>Children above 3 years will be charged full ticket.</li>
            <li>
              Visit our website at{' '}
              <a href="https://www.cinepolisindia.com/" target="_blank" rel="noopener noreferrer">
                www.cinepolisindia.com
              </a>
            </li>
          </ul>
          <section className="cinepolis-meta">
            <p>{parsedData?.customData?.pos}</p>
            <p>{parsedData?.customData?.qrString}</p>
          </section>
        </section>
      </>
    );
  } else if (isConnplex) {
    return (
      <>
        <p>{'Terms & Conditions'}</p>
        <ul>
          <li>
            1. The viewer below the age of 18 years cannot be admitted for movies certified as A
            rated.
          </li>
          <li>2. Entry in the Audi will be given before 5 minutes of the showtime.</li>
          <li>3. Tickets are mandatory for children aged 3 years and above.</li>
          <li>4. The ticket is only valid for the date and the show printed on the ticket.</li>
          <li>5. The ticket is neither Transferable nor Refundable.</li>
          <li>6. Rights of admission are reserved.</li>
          <li>
            7. Management will not allow the viewer to enter/ carry any stuff from outside (eatable/
            drink) except one small water bottle per viewer.
          </li>
          <li>
            8. Items like Laptop bags, Baggage, food packages, cameras, cigarettes/ e-cigarettes/
            bidi, knives, firearms, lighters/ matchboxes, and all types of inflammable objects are
            strictly restricted.
          </li>
          <li>
            9. Only online booking partner messages are allowed, printouts & forwarded messages are
            allowed for both Movies Tickets and F&B.
          </li>
          <li>
            10. If there is any show breakdown or cancellation due to technical reasons, money will
            be refunded according to the booking process. Online booking will get a refund online
            and not at the theatre.
          </li>
          <li>
            11. A minimum of 6 tickets is required to run the show, or the show will be cancelled.
          </li>
        </ul>
      </>
    );
  } else if (isRoongta) {
    return (
      <>
        <p>{'Rules & Regulations'}</p>
        <ul>
          <li>
            Following items Are Strictly Prohibited: Handbags, Carry Bags, Mobile Charger,
            Electronics items, Helmets, Bottles, Tiffin Box, Outside Eatables (Including Guthka,
            Pan- Masala, Outside Beverages, Chewing Gum, Lighter Match Box, Cigarette, Firearms &
            All kind of inflammable objects etc.
          </li>
          <li>
            Pricing of the tickets as shown against the show- timing are all inclusive admission,
            Prices.
          </li>
          <li>
            A non-refundable 3D glass usage fee of Rs. 30 per ticket would be Charged per ticket for
            all 3D shows.
          </li>
          <li>The Cinema Management reserves the rights of admission.</li>
          <li>For ‘A’ rated content patrons below 18 years of age Will not be allowed to enter.</li>
          <li>Please go through the emergency evacuation plan of the Cinema & the Auditorium.</li>
          <li>Tickets once sold are non- transferable & non- refundable.</li>
          <li>Please check details on the ticket & your balance before leaving the counter.</li>
          <li>Children above 3 years Will be charged full ticket.</li>
        </ul>
      </>
    );
  } else if (isMovieMaxTicket) {
    return (
      <>
        <p>COVID-19 Safety Rules:</p>
        <ul>
          <li>Masks are always mandatory in the cinemas unless eating.</li>
          <li>Social distancing will be maintained between seats.</li>
          <li>You are advised to show the Aarogya Setu App at the cinema entrance.</li>
          <li>
            Temperature Screening will be performed at the entrance. Guests with-high temperature (`
            {'>'}` 99.2 F) will be prohibited from entering the cinema and full ticket amount will
            be refunded.
          </li>
          <li>Sanitizers are available for patrons.</li>
        </ul>
        <p>Rules and Regulations:</p>
        <li>Outside foods are not allowed in cinema premises.</li>
        <li>
          Following Items Are Strictly Prohibited: Handbags, Carry Bags, Mobile Charger, Electronic
          items, Helmets, Bottles, Tiffin Box, Outside Eatables (Including Guthka, Pan-masala),
          Outside Beverages, Chewing Gum, Lighter, Match Box, Cigarette, Firearms & All kind of
          inflammable objects etc
        </li>
        <li>
          Pricing of the tickets as shown against the show-timing are all inclusive admission
          prices.
        </li>
        <li>
          A non-refundable 3D glass usage fee of Rs. 30 per ticket would be charged per ticket for
          all 3D shows.
        </li>
        <li>The Cinema Management reserves the rights of admission.</li>
        <li>For ‘A’ rated content, patrons below 18 years of age will not be allowed to enter.</li>
        <li>Please go through the emergency evacuation plan of the cinema & the auditorium.</li>
        <li>Tickets once sold are non-transferable & non-refundable.</li>
        <li>Please check detail on the ticket & your balance before leaving the counter.</li>
        <li>Children above 3 years will be charged full ticket.</li>
        <li>
          Visit our website at&nbsp;
          <a href="https://www.moviemax.co.in/" target="_blank" rel="noopener noreferrer">
            www.moviemax.co.in
          </a>
        </li>
      </>
    );
  } else if (isRelaxo) {
    return (
      <RelaxoD>
        <img
          width={'100%'}
          src="https://assets.billme.co.in/relaxo-assets/relaxo-term-and-condition.webp"
        />
      </RelaxoD>
    );
  } else if (isGoColors) {
    return (
      <GoColorstag>
        <div style={{ textAlign: 'center' }}>
          <img src={brandLogo} alt={`${brandName} logo`} height="30px" className="miraj-logo" />
        </div>
        <ReturnPolicyButton
          href="https://gocolors.com/pages/returns-exchange-refund-policy"
          target="_blank"
          rel="noopener noreferrer">
          Return and Exchange Policy
        </ReturnPolicyButton>

        <OfferPolicyButton
          href="https://gocolors.com/pages/returns-exchange-refund-policy"
          target="_blank"
          rel="noopener noreferrer">
          Offer Policy
        </OfferPolicyButton>
      </GoColorstag>
    );
  } else if (isBewakoof) {
    return (
      <StyledUl>
        <StyledLi>
          We will accept exchanges within 15 days of purchase, only if the Sales Invoice is
          presented along with the merchandise.
        </StyledLi>
        <StyledLi>
          {' '}
          ⁠All merchandise which needs to be exchanged should be in its original condition along
          with the barcode tags intact.
        </StyledLi>
        <StyledLi>
          {' '}
          Altered Merchandise, Inner Wear and Masks cannot be exchanged or returned.
        </StyledLi>
        {/* <Dash color="hsla(0, 0%, 0%, 0.5)" dashLength="100%" /> */}
        <StyledLi> Please follow inner wash care label instructions.</StyledLi>
        <StyledLi>
          {' '}
          We reserve all rights to determine whether merchandise is damaged or used.
        </StyledLi>
        <PTag>Exchange Policy</PTag>
        <StyledLi>
          {' '}
          If you are not delighted with your purchase, we will be happy to exchange your product
          within 15 days from the date of purchase. The sales Invoice must mandatorily be presented
          at the time of exchange, along with the intact product and original tags.
        </StyledLi>
        <StyledLi>
          {' '}
          ⁠For feedback and complaints, please write to us at:
          <a target="_blank" href="https://bit.ly/2AEtEGX">
            {' '}
            care@bewakoof.com{' '}
          </a>
          .
        </StyledLi>
      </StyledUl>
    );
  } else if (isSamoh) {
    return (
      <div style={{ textAlign: 'center' }}>
        <p>Eligibility for Exchange/Returns</p>
        <p>
          Products purchased once can be returned or exchanged within three weeks (21 days) from the
          date of purchase. Ensure the product is unused and in the same condition as when
          purchased. During return/exchange, please provide the original invoice, the product’s
          original packaging and price tags. In case of returns, a credit note of the ‘spent amount’
          will be issued to you. The credit note will be valid for six (6) months from the date of
          issue and redeemable at any of our stores pan-India.
        </p>
        <p>
          Customised products and purchases made during sales/discounts are not eligible for returns
          or exchanges.
        </p>
        <p>Home, jewellery, and accessories are also not applicable for refund/return/exchange.</p>
        <Dash color="hsla(0, 0%, 0%, 0.5)" dashLength="100%" />
        <p>
          Furthermore, all returns are subject to quality checks from our end. (Just to be safe!)
        </p>
        <p>
          Once the return is confirmed, you will be offered an exchange or credit note as per your
          order eligibility.
        </p>
        <p>
          PLEASE NOTE: Returns initiated after 3 weeks (21 days) from the date of purchase will not
          be entertained.
        </p>
      </div>
    );
  } else if (isZudio) {
    return (
      <ZudioDiv>
        <p>
          Trent Limited
          <br />
          Zudio - Unit Of Tent Ltd.
          <br />
          Regd. Office:
          <br />
          Bombay House, 24 Homi Mody Street,
          <br />
          Mumbai - 400001, 27 - Maharashtra
          <br />
          CIN: L24240MH1952PLC008951
          <br />
          GST IN: 27AAACL1838J1ZG
          <br />
        </p>
        <Dash color="hsla(0, 0%, 0%, 0.5)" dashLength="100%" />
        <ZudioPTag>
          Irresistible Fashion
          <br />
          Irresistible Prices
        </ZudioPTag>
        <p>
          www.zudio.com |{' '}
          <InstagramBW style={{ width: '20px', height: '14px', marginBottom: '-2px' }} /> @myzudio
        </p>
        <Dash color="hsla(0, 0%, 0%, 0.5)" dashLength="100%" />
        <p>
          <ZudioDivTag>
            If you are not happy,
            <br />
            we're not happy.
          </ZudioDivTag>
          Just ask for an exchange across any Zudio store
        </p>
        <p>
          <ZudioDivTagCust>If you have an invoice</ZudioDivTagCust>
          We will give you an exchange or complete credit as per the invoice value
        </p>
        <p>
          <ZudioDivTagCust>If you do not have an invoice</ZudioDivTagCust>
          We will give you a credit note as per the current selling price mentioned in the system
        </p>
        <p>
          *T&C apply. No exchange or refund on cosmetics & mens and womens briefs. Exceptions only
          for faulty product.
        </p>
      </ZudioDiv>
    );
  } else if (isMiraj) {
    return (
      <>
        <p>Terms & Conditions</p>
        <ul>
          {parsedData?.customData.ticketType !== 'CINEPOLIS_FOOD' && getMirajMovieTnC()}
          {getFoodVoucherTnc()}
        </ul>
      </>
    );
  } else if (isNyCinemas) {
    return (
      <>
        <ol>
          {VENDOR_META.NyCinemas.tnc.map((tnc, index) => (
            <li key={index}>{tnc}</li>
          ))}
        </ol>
      </>
    );
  } else if (isManyavar) {
    return (
      <StyledUl>
        <StyledLi>
          Your purchasing the product shall be deemed as your acceptance of the Terms Conditions
          hereunder.
        </StyledLi>
        <StyledLi>
          {' '}
          You are requested to collect the delivery of your product after proper verification of it.
          Once the product isdelivered and/or bought, it shall be implied that you have accepted the
          same after proper checking and verification.
        </StyledLi>
        <StyledLi> Product Exchange Policy:-</StyledLi>
        <ol type="a">
          <StyledLi>
            {' '}
            Exchange of product is applicable in any of the stores across the country.{' '}
          </StyledLi>
          <StyledLi>
            {' '}
            Any exchange must be within 15 days from the date of purchase (except weekends and
            public Holidays) along with the relevant cash memo & original price tags intact &
            unaltered.
          </StyledLi>
          .
          <StyledLi>
            {' '}
            Exchange or return shall not be applicable on products that are used, washed,
            tapered/altered, without tag, purchased in promotion/offer/discount, gift card or
            coupons and also on Accessories like Juti, Safa, Kilangi, Mala, <br />
            Brooch, Scarf, Dupatta/Bandana, Socks, Pocketsquare, Bow, Handkerchief, earing, clutch,
            potli, necklace etc.
          </StyledLi>
          <StyledLi>
            {' '}
            Return shall only be allowed in case of genuine size and/ or manufacturing defect;{' '}
          </StyledLi>
          <StyledLi> Invoice/cash receipt must be mandatorily produced;</StyledLi>
        </ol>
        <StyledLi> General disclaimer:-</StyledLi>
        <ol type="a">
          <StyledLi>
            {' '}
            We are committed to product safety and care. Nevertheless, we will not be responsible
            for the allergic reactions that some people may have due to special sensitivities to
            certain fabrics or materials. Customers concerned with fabric allergies or other special
            sensitivities are encouraged to carefully monitor the use of the garment and immediately
            discontinue the use of the product in the event of any adverse reactions and promptly
            contact your physician.
          </StyledLi>
          <StyledLi>
            {' '}
            We are unable to take guarantee of product’s embroidery and/or any color bleeding due to
            mishandling/ sweating.
          </StyledLi>
          <StyledLi>
            {' '}
            We request you to read the washing instructions carefully as stated on the product or on
            the tag of the product and follow the same to avoid damage to it.
          </StyledLi>
        </ol>{' '}
        <StyledLi>
          {' '}
          Any altered or undelivered product should be collected within 15 days from the date on
          which given for alteration/ purchased. We shall not be liable for the product being
          misplaced after 15 days’ collection window.
        </StyledLi>
        <StyledLi style={{ marginBottom: '15px' }}>
          {' '}
          Products once purchased cannot be used for commercial exploitation through rental or
          resale or similar use and such use would be treated as breach of the conditions of
          purchase.
        </StyledLi>
        <StyledLi style={{ marginBottom: '15px' }}>
          {' '}
          By providing your contact number and email ID, you are consenting to receive messages and
          in case you want to unsubscribe, please email at{' '}
          <span style={{ textDecoration: 'underline', color: 'blue' }}>
            care@vedantfashions.com{' '}
          </span>
        </StyledLi>
        <StyledLi style={{ marginBottom: '15px' }}>
          {' '}
          We reserve the right to amend and/or alter these terms & conditions any time at our sole
          discretion.
        </StyledLi>
        <StyledLi style={{ marginBottom: '15px' }}>
          {' '}
          Disputes if any should be subject to Kolkata Jurisdiction only.
        </StyledLi>
        <StyledLi style={{ marginBottom: '15px' }}>
          {' '}
          For any queries please mail us at{' '}
          <span style={{ textDecoration: 'underline', color: 'blue' }}>
            care@vedantfashions.com{' '}
          </span>
        </StyledLi>
      </StyledUl>
    );
  } else {
    return (
      <>
        <p>COVID-19 Safety Rules:</p>
        <ul>
          <li>Masks are always mandatory in the cinemas unless eating.</li>
          <li>You are advised to show the Arogya Setu App at the cinema entrance.</li>
          <li>
            Temperature Screening will be performed at the entrance. Guests with-high temperature (
            {'>'} 99.2 F) will be prohibited from entering the cinema and full ticket amount will be
            refunded.
          </li>
          <li>Sanitizers are available for patrons</li>
        </ul>
        <p>Please Note:</p>
        <ul>
          <li>
            Following Items Are Strictly Prohibited: Handbags, Carry Bags, Mobile Charger,
            Electronic items, Helmets, Bottles, Tiffin Box, Outside Eatables (Including Guthka,
            Pan-masala), Outside Beverages, Chewing Gum, Lighter, Match Box, Cigarette, Firearms &
            All kind of inflammable objects etc.
          </li>
          <li>
            Pricing of the tickets as shown against the show-timing are all inclusive admission
            prices.
          </li>
          <li>
            A non-refundable 3D glass usage fee of Rs. 20 per ticket would be charged per ticket for
            all 3D shows.
          </li>
          <li>The Cinema Management reserves the rights of admission.</li>
          <li>
            For ‘A’ rated content, patrons below 18 years of age will not be allowed to enter.
          </li>
          <li>Tickets once sold are non-transferable & non-refundable.</li>
          <li>Please check detail on the ticket & your balance before leaving the counter.</li>
          <li>Children above 3 years will be charged full ticket.</li>
          <li>Out side Foods is strictly Prohibited.</li>
          <li>For Online booking Visit www.prasadz.com</li>
        </ul>
      </>
    );
  }
};

export default TnCStaticContent;

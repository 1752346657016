import styled, { css } from 'styled-components';
import { CarouselProvider } from 'pure-react-carousel';

import { BillMaxWidth } from '../constants';

import downloadSvg from '../../assets/images/wellness/download.svg';
import whatsapp from '../../assets/images/wellness/whatsapp.svg';
import smartphone from '../../assets/images/wellness/smartphone.svg';
import webstore from '../../assets/images/wellness/webstore.svg';
import toll from '../../assets/images/wellness/24.svg';
import pill from '../../assets/images/wellness/pill.svg';
import greenPill from '../../assets/images/wellness/pill-green.svg';
import file from '../../assets/images/wellness/file.svg';
import greenFile from '../../assets/images/wellness/file-green.svg';
import location from '../../assets/images/wellness/location.svg';
import phone from '../../assets/images/wellness/phone.svg';
import { $Flexbox } from '../../css/styles';

export const $BestSellerFeedback = styled.div`
  p.title {
    color: #474747;
    font-size: 14px;
    margin: 0 0 5px;
  }
  p.titlekaya {
    color: #474747;
    font-size: 14px;
    margin: 0 0 5px;
    padding: 5px;
  }
  h5 {
    margin: 0;
    font-size: 20px;
  }
  div.ratings {
    display: flex;
    height: 50px;
    margin: auto;
    width: 330px;
    position: relative;
    padding-bottom: 20px;
    span {
      height: 30px;
      width: 100%;
      font-size: 14px;
      border-right: 0.5px solid rgba(255, 255, 255, 0.5);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:nth-child(11) {
        border-right: none;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &.unlikely {
        background-color: #f86868;
      }
      &.probably {
        background-color: #ffc200;
      }
      &.forSure {
        background-color: #66f369;
      }
    }
    small {
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      &.unlikely {
        color: #f86868;
        left: 70px;
      }
      &.probably {
        color: #ffc200;
        right: 65px;
      }
      &.forSure {
        color: #66f369;
        right: 4px;
      }
    }
  }
`;

export const $Slider = styled.div`
  div {
    margin-bottom: 0 !important;
  }
`;

export const $BSFLogo = styled.div`
  text-align: center;
  img {
    width: 160px;
    min-height: 60px;
    object-fit: contain;
  }
`;

export const $BSFSurvey = styled.div`
  font-family: Roboto, Medium;
  margin: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.l}`};
  h2 {
    font-size: 28px;
    color: #000000;
    font-weight: normal;
    margin: 0 0 ${({ theme }) => theme.spacing.s};
  }
  h4 {
    color: ${(props) =>
      props.isNykaaStore ? `#f82779` : props.isMcDFeedback ? `#d62718` : `#00268A`};
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 40px;
    cursor: pointer;
  }
  p {
    color: #000;
    font-size: 18px;
    font-weight: normal;
    margin: 0 0 40px;
  }
  div {
    padding: 2px 0;
    span {
      display: flex;
      margin-bottom: ${({ theme }) => theme.spacing.s};
      big {
        width: 20px;
        height: 20px;
      }
      label {
        font-size: 16px;
        width: 235px;
        margin-left: ${({ theme }) => theme.spacing.m};
        margin-top: 2px;
      }
    }
  }
  .comment {
    margin: ${({ theme }) => theme.spacing.m} 0;
    p {
      font-size: 16px;
      margin-bottom: ${({ theme }) => theme.spacing.s};
    }
    textarea {
      width: 100%;
      padding: 8px;
      border-radius: 4px;
    }
  }
  .feedback {
    margin: ${({ theme }) => theme.spacing.m} 0;
    .flex {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: ${({ theme }) => theme.spacing.l};
      svg {
        width: 80px;
      }
      h3 {
        font-size: 26px;
        margin: 0;
      }
    }
    p {
      font-size: 16px;
      margin-bottom: ${({ theme }) => theme.spacing.m};
      font-weight: 600;
    }
    label {
      display: block;
      font-size: 14px;
      margin-bottom: ${({ theme }) => theme.spacing.xs};
      font-weight: 600;
    }
    input {
      width: 100%;
      padding: 8px;
      border-radius: 4px;
      margin-bottom: ${({ theme }) => theme.spacing.m};
      border: 1px solid #dbdbdb;
    }
  }
`;

export const $BSFSubmit = styled.button`
  color: #fff;
  background-color: ${(props) =>
    props.isNykaaStore ? `#f82779` : props.isMcDFeedback ? `#d62718` : `#00268A`};
  border: none;
  padding: 8px 50px;
  border-radius: 4px;
  width: 150px;
  display: block;
  margin: ${({ theme }) => theme.spacing.l} 0;
  margin-left: auto;
  font-size: 14px;
  ${({ isCentered }) =>
    isCentered &&
    css`
      margin-right: auto;
      width: 100%;
    `}
`;

export const $DatePickerInput = styled.div`
  position: relative;
  svg {
    position: absolute;
    width: 16px;
    right: 8px;
    top: 10px;
  }
`;

export const Dash = styled.div`
  background-repeat: no-repeat;
  height: ${({ height }) => height || '1px'};
  background: linear-gradient(
    90deg,
    ${({ color }) => color || '#707070'} ${({ dashLength }) => dashLength || '50%'},
    #fff 0
  );
  background-size: ${({ type }) =>
    type === 'thick' ? '12px 1px' : type === 'thinnest' ? '4px 2px' : '8px 1px'};
  margin: ${({ margin }) => margin || '10px 0px'};
`;

export const BlurWhiteOverlay = styled.div`
  width: 100%;
  height: 150px;
  background: linear-gradient(hsla(0, 0%, 100%, 0), hsl(0, 0%, 100%));
  position: absolute;
  bottom: 0px;
  z-index: 20;
`;

export const BillImage = styled.img.attrs(() => ({
  alt: '',
}))`
  object-fit: ${(props) => (props.isBillLarger ? 'cover' : '')};
  object-position: 0px 0px;
  width: 100%;
  margin-top: ${(props) => props.marginTop};
`;

export const $RRGoldHeader = styled.img.attrs(() => ({
  alt: '',
  src: 'https://assets.billme.co.in/public/bill-template-assets/rr-gold/3.jpg',
}))`
  object-fit: contain;
  object-position: center;
`;

export const $RRGoldFooter = styled.img.attrs(() => ({
  alt: '',
  src: 'https://assets.billme.co.in/public/bill-template-assets/rr-gold/RR_Footer.jpg',
}))`
  object-fit: contain;
  object-position: center;
`;

export const $RRTerms = styled.div`
  margin: 10px 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  a {
    color: ${({ theme }) => theme.color.doveGray};
    display: flex;
    span {
      background-size: contain;
      background-position: center top;
      margin-right: ${(props) => props.theme.spacing.m};
      background-repeat: no-repeat;
      display: block;
      height: 20px;
      width: 20px;
      background-image: url(${whatsapp});
    }
  }
`;

export const DevyaniTable = styled.table`
  width: 100%;
  border-collapse: ${(props) => (props.isBK || props.isBorderCollapsed ? 'collapse' : 'separate')};
  width: 100%;
  border-spacing: ${(props) => (props.wellness ? props.theme.spacing.s : '10px')};

  td {
    font-family: ${(props) => props.wellness && 'AvenirLTStd-Medium, sans-serif'};
    font-size: ${(props) => props.wellness && '12px'};
  }

  th,
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
`;

export const CenterText = styled.p`
  &&& {
    text-align: center;
  }
`;

export const CinepolisTable = styled(DevyaniTable)`
  th {
    font-weight: bold;
    vertical-align: top;
  }
`;

export const MirajFoodTable = styled(DevyaniTable)`
  border-spacing: 1px !important;
  th {
    font-weight: bold;
  }
`;

export const CinepolisMain = styled.div`
  padding: ${(props) => props.theme.spacing.xl};
  background-color: #fff;

  * {
    font-size: 10px;
    font-family: monospace;
  }
`;

export const ConnplexisMain = styled.div`
  padding: ${(props) => props.theme.spacing.xl};
  background-color: #fff;

  * {
    font-size: 12px;
    font-family: roboto;
  }
`;
export const MirajFoodMain = styled.div`
  padding: ${(props) => props.theme.spacing.m};
  background-color: ${(props) => props.backgroundColor};

  * {
    font-size: ${(props) => props.fontSize};
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.color};
  }
`;

export const RightBlock = styled.div`
  &&& {
    text-align: right;
  }
`;

export const LeftBlock = styled.div`
  &&& {
    text-align: left;
    margin-top: 20px;
  }
`;
export const CinepolisFoodContainer = styled.div`
  width: auto;
  position: relative;
  z-index: 5;
`;

export const MirajFoodContainer = styled.div`
  width: ${(props) => !props.isCarnival && '92%'};
  position: relative;
  z-index: 5;
  margin: auto;
`;

export const BoldHeader = styled.h2`
  font-weight: 700;
  margin: 0px 0px 5px;
  font-family: 'DINPro-Bold', sans-serif;
`;

export const StyledSubitem = styled.div`
  p {
    margin: 5px 0px;
  }

  ul {
    list-style: none;
    padding-left: 10px;
    margin-top: 0px;
  }
`;

export const BurgerKingTable = styled(DevyaniTable)`
  th,
  td {
    vertical-align: top;
  }
`;

export const BoldText = styled.p`
  font-weight: 800;
  font-family: ${(props) => props.wellness && 'AvenirLTStd-Heavy, sans-serif'};
  text-transform: ${(props) => props.wellness && 'capitalize'};
`;

export const $Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: ${BillMaxWidth};
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
  overflow: hidden;
  background-image: ${(props) => props.backgroundImage};
  background-size: ${(props) => props.backgroundSize};
`;
export const $RzpContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: ${BillMaxWidth};
  background-color: #0c2651;
  position: relative;
  overflow: hidden;
  background-image: ${(props) => props.backgroundImage};
  background-size: ${(props) => props.backgroundSize};
`;
export const $FbContainer = styled.div`
  top: ${(props) => props.top};
`;

export const $TableHeading = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

    p {
    &:first-child {
      width: ${(props) => props.wellness && '160px'};
    }
    margin: 0px;
    color: ${({ theme }) => theme.color.doveGray};
    font-family: AvenirLTStd-Heavy, sans-serif;
    font-size: 12px;
  }
`;

export const $Line = styled.div`
  margin: ${(props) => props.theme.spacing.s} 0px;
  height: 2px;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
`;

export const $StoreHeader = styled.div`
  min-height: 100px;
  height: 100%;
  display: flex;
  justify-content: ${({ isMarcaEcomm }) => (isMarcaEcomm ? 'center' : 'space-between')};
  align-items: center;
  padding: ${(props) => `${props.theme.spacing.m} ${props.theme.spacing.xxl}`};

  div {
    min-width: 105px;
    text-align: right;

    p {
      font-family: AvenirLTStd-Heavy, sans-serif;
      font-size: ${(props) => props.theme.spacing.m};
      letter-spacing: 0px;
      color: ${(props) => props.theme.color.mineShaft};
      margin: 0 0 ${(props) => props.theme.spacing.s};
      font-weight: 900;
    }
  }
  > img {
    margin-right: ${(props) => (props.isMarcaEcomm ? '0px' : props.theme.spacing.x4l)};
    width: auto;
    height: auto;
    max-width: ${({ isMarcaEcomm }) => (isMarcaEcomm ? '180px' : '150px')};
    max-height: 80px;
    max-height: ${(props) => props.isNykaaEcom && '100px'};
  }
`;

export const $BaseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
`;

export const $ButtonWithIcon = styled($BaseButton)`
  margin: 0;
  padding: 0;
  width: ${(props) => (props.right ? '100%' : 'auto')};
  font-family: AvenirLTStd-Medium, sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin: ${(props) =>
      props.right ? '0 0 0 ' + props.theme.spacing.s : '0 ' + props.theme.spacing.s};

    img {
      vertical-align: middle;
      transform: ${(props) => props.rotate && `rotate(${props.rotate})`};
    }
  }
`;

export const $NetTotal = styled.div`
  background-color: ${({ theme }) => theme.color.aquaHaze};
  padding: ${(props) => props.theme.spacing.l};
  border-radius: 6px;
  block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin: 0;
      color: ${(props) => props.theme.color.greenPea};
      font-family: AvenirLTStd-Heavy, sans-serif;
    }
    small {
      font-weight: 800;
      font-size: 18px;
      color: ${(props) => props.theme.color.greenPea};
    }
  }
`;

export const $CostBreakupButton = styled($ButtonWithIcon)`
  color: ${({ theme }) => theme.color.mineShaft};
  text-decoration: underline;
  margin-top: ${(props) => props.theme.spacing.s};
  padding: 0px;
  span {
    img {
      transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
    }
  }
`;

export const $WellnessFooter = styled.div`
  width: 100%;
  background-color: transparent;
  position: fixed;
  bottom: 68px;

  button {
    font-size: 14px;
    float: right;
    margin-right: 18px;
    width: 150px;
    height: 38px;
    border: 2px solid #fff;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.color.greenPea};
    color: #fff;
    padding: ${(props) => props.theme.spacing.s} ${(props) => props.theme.spacing.l};
    text-decoration: none;
    box-shadow: 0px 4px 7px 0px #aaa;
    z-index: 1;
  }
`;

export const $RatingContainer = styled.div`
  text-align: center;
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.fontSize};
  box-shadow: ${(props) => props.boxShadow};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) =>
    props.isBestSeller
      ? `${props.theme.spacing.s} 0px 1px`
      : props.padding
        ? '0px 0px'
        : `${props.theme.spacing.xl} 0px`};
  font-family: ${(props) => props.fontFamily};
  background-color: ${(props) => props.backgroundColor || props.theme.color.aquaHaze};
  color: ${(props) => props.color};
  background-image: url(${(props) => props.bgImg});
  background-size: ${(props) => props.bgSize};
  margin: ${(props) => props.margin};
  border-radius: ${(props) => props.borderRadius};
  border-bottom: ${(props) => props.borderBottom};
  border: ${(props) => props.border};
  div {
    div {
      margin-bottom: ${({ theme }) => theme.spacing.s};
    }
  }
`;

export const $InViewAnim = styled.div`
  width: 100%;
  position: relative;
  left: ${(props) => (props.inView ? '0' : '-50%')};
  transition: 0.5s;
  background-color: ${(props) => props.backgroundColor};
`;

export const $DiscountBar = styled.div`
  margin: ${({ theme }) => `${theme.spacing.m} 0px`};
  padding: ${({ theme }) => `${theme.spacing.xs} 0px`};
  color: ${({ theme }) => theme.color.mineShaft};
  background-color: ${({ theme }) => theme.color.earlyDawn};
  border: 2px dashed ${({ theme }) => theme.color.dandelion};
  text-align: center;
  border-radius: 4px;
  font-family: ${(props) => props.wellness && 'AvenirLTStd-Heavy'};
`;

export const $PatientInfo = styled.div`
  background: #ffffff;
  position: relative;
  text-align: center;
  padding: 0;

  table {
    width: 220px;
    margin: auto;
    tr {
      margin-bottom: ${(props) => props.theme.spacing.s};
      text-align: left;
      font-size: 12px;
      td {
        width: 80px;
        font-family: AvenirLTStd-Medium, sans-serif;
      }
      th {
        font-family: AvenirLTStd-Heavy, sans-serif;
      }
    }
  }
`;

export const $InvoiceHead = styled.div`
  background: #ffffff;
  height: 54px;
  position: relative;
  text-align: center;
  padding: ${(props) => props.theme.spacing.xxl} 0;

  p {
    margin: ${(props) => props.theme.spacing.xxs} 0;
    text-align: center;
    letter-spacing: 0px;
    color: #313131;
  }
`;

export const $InvoiceText = styled.p`
  margin: ${(props) => props.theme.spacing.xxs} 0;
  text-align: center;
  letter-spacing: 0px;
  color: ${(props) => props.theme.color.mineShaft};
  font-family: ${(props) => (props.head ? 'AvenirLTStd-Heavy' : 'AvenirLTStd-Medium')}, sans-serif;
  font-size: ${(props) => (props.head ? '14px' : '12px')};
  font-weight: ${(props) => props.head && '900'};
`;

export const $IconContact = styled.a`
  text-decoration: none;
  min-width: 33%;

  span {
    background-size: contain;
    background-position: center top;
    margin: ${(props) => props.theme.spacing.s} auto;
    background-repeat: no-repeat;
    display: block;
    height: 35px;
    width: ${(props) =>
      ({
        smartphone: '23px',
        webstore: '38px',
        whatsapp: '35px',
        toll: '32px',
      })[props.icon]};
    background-image: url('${(props) =>
      ({
        smartphone: smartphone,
        whatsapp: whatsapp,
        webstore: webstore,
        toll: toll,
      })[props.icon]}');
  }

  p {
    letter-spacing: 0px;
    color: #313131;
    font-size: 12px;
    margin: ${(props) => props.theme.spacing.xs} 0 0;
    text-decoration: none;
    font-family: AvenirLTStd-Medium, sans-serif;
  }
`;

export const $Contact = styled.div`
  background: #ffffff;
  position: relative;
  text-align: center;
  margin: ${(props) => props.theme.spacing.l} 0px ${(props) => props.theme.spacing.x4l};

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  a {
    margin-top: ${(props) => props.theme.spacing.xl};
    display: block;
    text-align: center;
    letter-spacing: 0px;
    color: ${(props) => props.theme.color.greenPea};
    font-size: 12px;
    font-family: AvenirLTStd-Medium, sans-serif;
  }
`;

export const $TabMenu = styled.div`
  background: #ffffff;
  height: 29px;
  position: relative;
  text-align: center;
  display: flex;
  padding: 6px 0 ${(props) => props.theme.spacing.x3l};
`;

export const $Tab = styled.div`
  cursor: default;
  text-align: center;
  flex: 1;
  letter-spacing: 0px;
  font-size: 12px;
  border-bottom: 2px solid #00000029;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  font-weight: 900;
  color: ${(props) => props.tab && props.theme.color.greenPea};
  font-family: AvenirLTStd-Heavy, sans-serif;

  span {
    display: block;
    margin-right: ${(props) => props.theme.spacing.s};
    background-size: contain;
    background-image: url('${(props) =>
      ({
        purchase: props.tab ? greenPill : pill,
        info: props.tab ? greenFile : file,
      })[props.ico]}');
    width: ${(props) =>
      ({
        purchase: '14px',
        info: '12px',
      })[props.ico]};
    height: ${(props) =>
      ({
        purchase: '14px',
        info: '15px',
      })[props.ico]};
  }

  ::after {
    position: absolute;
    bottom: -2px;
    right: ${(props) => props.ico === 'purchase' && '0'};
    left: ${(props) => props.ico !== 'purchase' && '0'};
    height: 2px;
    content: '';
    background: ${(props) => props.theme.color.greenPea};
    width: ${(props) => (props.tab ? '100%' : 0)};
    transition: 500ms;
  }
`;

export const $AdDetail = styled.div`
    color: ${(props) => props.color}
    background: ${(props) => props.background}
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    flex: 1;
`;
export const $CineIndoFnb = styled.div`
  font-size: 1rem;
  font-family: Montserrat;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -30px;
  font-weight: 600;
  color: #08285b;
`;

export const $AdDescript = styled.p`
  font-size: 10px;
  font-weight: bold;
  display: flex;
  letter-spacing: 0px;
  color: ${(props) => (props.color == true ? 'white !important' : props.theme.color.mineShaft)};
  text-transform: uppercase;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin-bottom: ${(props) => props.ico === 'space' && '0 !important'};

  &&& {
    margin: 0px;
  }

  span {
    display: block !important;
    margin-right: 7px;
    width: ${(props) =>
      ({
        location: '19px',
        phone: '12px',
        space: '12px',
      })[props.ico]};
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('${(props) =>
      ({
        location: location,
        phone: phone,
      })[props.ico]}');
  }
`;

export const $SlideAnim = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 10px 0px hsla(0, 0%, 40%, 0.2);
  // background-color: ${(props) => props.backgroundColor}

  ${$Flexbox} {
    width: ${({ isMarcaEcomm }) => (isMarcaEcomm ? '100%' : '200%')};
  }
`;

export const $Block = styled.div`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-top: ${(props) => props.top};
  background-color: ${(props) => props.backgroundColor};
`;

export const $CarouselContainer = styled(CarouselProvider)`
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  position: relative;

  * {
    max-height: ${({ isBanner }) => (isBanner ? '100px' : '750px')};
  }

  img {
    object-fit: contain;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const $Div = styled.div`
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
`;

export const $BSFStoreButton = styled($BaseButton)`
  color: #00268a;
  font-weight: bold;
`;

export const $BSFContainer = styled.div`
  padding: 16px;
  img {
    background-color: #e3e3e3;
    display: block;
    width: 300px;
    max-width: 100%;
    height: 300px;
    margin: auto;
    object-fit: contain;
  }
  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    p {
      margin-bottom: 2px;
      font-weight: 500;
    }
    span {
      font-size: 12px;
    }
  }
`;

export const $BrokenLink = styled($Container)`
  font-family: 'Nunito', sans-serif !important;
  img {
    display: block;
    margin: auto;
  }
  .hlogo {
    margin: 60px auto;
    width: 150px;
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  button {
    display: block;
    margin: 60px auto 0;
    text-align: center;
    font-size: 14px;
    background-color: #00abf0;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 8px 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

export const $CarnivalGradiantHeader = styled.div`
  background: -webkit-linear-gradient(top, rgb(107, 6, 6) 0%, rgb(46, 2, 2) 60%, rgb(6, 0, 0) 100%);
  background: -o-linear-gradient(top, rgb(107, 6, 6) 0%, rgb(46, 2, 2) 60%, rgb(6, 0, 0) 100%);
  background: -ms-linear-gradient(top, rgb(107, 6, 6) 0%, rgb(46, 2, 2) 60%, rgb(6, 0, 0) 100%);
  background: -moz-linear-gradient(top, rgb(107, 6, 6) 0%, rgb(46, 2, 2) 60%, rgb(6, 0, 0) 100%);
  background: linear-gradient(to bottom, rgb(107, 6, 6) 0%, rgb(46, 2, 2) 60%, rgb(6, 0, 0) 100%);
  text-align: center;

  img {
    display: block;
    margin: auto;
    width: 30%;
    padding: 0.6em;
  }
`;

export const $CinepolisIndonesiaStyles = styled.div`
  .secondary-details {
    margin-top: 25px;
    p {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      margin-top: 10px;
    }
    .font-normal {
      font-weight: normal;
    }
  }
`;

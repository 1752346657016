import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { $CampaignContainer, $CarouselIndicator } from './campaigns.styled';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

export const CarouselSlider = ({
  assets,
  getCouponCode,
  maxHeight,
  isBannerInBill,
  trackActivity,
  isViewed,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const settings = {
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    showThumbs: false,
    useKeyboardArrows: false,
    stopOnHover: false,
    dynamicHeight: true,
    infiniteLoop: true,
    autoPlay: true,
    interval: 5000,
    renderIndicator: (_, isSelected, index) => {
      if (isSelected) {
        setSelectedIndex(index);
        isViewed && trackActivity(null, assets[index]?.assetId);
      }
      return (
        <$CarouselIndicator isSelected={isSelected} isSeen={selectedIndex > index} key={index} />
      );
    },
  };

  const getContent = () => (
    <Carousel {...settings}>
      {assets?.map((item, i) => (
        <$CampaignContainer index={i} key={`slide${i}`} maxHeight={maxHeight}>
          <img src={item?.fileLocation} />
          {item?.redirectUrl && (
            <a target="_blank" rel="noopener" href={item?.redirectUrl} assetid={item.assetId} />
          )}
          <div className="box-shadow" />
          {getCouponCode(item)}
        </$CampaignContainer>
      ))}
    </Carousel>
  );

  return getContent();
};

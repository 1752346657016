import React from 'react';
import LostGraphic from '../../assets/images/lost-graphic.svg';
import BillMeHorizontalLogo from '../../assets/images/billme-blue-horizontal.svg';
import RZPBMLogoLight from '../../assets/images/logos/rzpbm-logo-Light.svg';
import { $BrokenLink } from '../templates/styles';

export const BrokenLink = () => {
  return (
    <$BrokenLink className="broken">
      <img className="hlogo" src={RZPBMLogoLight} />
      <img src={LostGraphic} />
      <p>
        The digital bill you are looking for <br />
        has been deleted or does not exist.
      </p>
      <button onClick={() => window.open('https://user.billme.co.in/', '_self')}>
        View Your Other Digital Bills
      </button>
    </$BrokenLink>
  );
};

import React from 'react';
import { FormattedNumber } from 'react-intl';

import RZPBMLogoLight from 'assets/images/logos/rzpbm-logo-dark.svg';
import styled from 'styled-components';
import RenderIf from '../RenderIf';

const Text = styled.p`
  font-family: AvenirLTStd-Heavy, sans-serif;
  color: white;
  font-weight: 550;
  font-size: 12px;
`;

const TextB = styled.p`
  font-family: AvenirLTStd-Heavy, sans-serif;
  color: white;
  font-size: 6px;
  text-align: center;
  margin-top: 13px;
  margin-bottom: -10px;
  font-style: italic;
`;

const TextValue = styled.p`
  font-family: AvenirLTStd-Heavy, sans-serif;
  color: white;
  font-weight: 550;
  font-size: 22px;
`;

const $Flexbox = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
`;

const ImageAlign = styled.img`
  width: 43px;
  margin-left: 7px;
  margin-bottom: -2px;
`;

const Payment = (props) => {
  const { parsedData, isPaymentStatus, flags } = props;

  return (
    <div>
      <RenderIf
        active={
          flags?.payNowThroughRzp &&
          parsedData?.paymentData?.status === 'unpaid' &&
          !isPaymentStatus &&
          parsedData?.paymentData?.rzpPaymentOrderId &&
          parsedData?.paymentData?.rzpPublicKey
        }>
        <div
          style={{
            margin: '10px',
            backgroundColor: '#2F5EE1',
            padding: '20px',
            borderRadius: '25px',
          }}>
          <Text style={{ marginBottom: '-8px' }}> Your total bill: </Text>
          <$Flexbox>
            <TextValue>
              {' '}
              <FormattedNumber
                value={parsedData?.billAmountData?.netPayableAmount}
                style={`currency`}
                currency={'INR'}
              />
            </TextValue>
            <button
              style={{
                fontFamily: 'AvenirLTStd-Heavy, sans-serif',
                color: 'rgb(47, 94, 225)',
                fontSize: '14px',
                width: '120px',
                borderRadius: '7px',
                margin: '20px',
                border: '1px solid white',
                fontWeight: 500,
              }}
              onClick={props.PayNowBtn}>
              Pay now
            </button>
          </$Flexbox>
          <Text style={{ marginRight: '-23px', marginTop: '-8px' }}>
            {' '}
            Pay using any payment method using Razorpay.{' '}
          </Text>
          <TextB>
            {' '}
            Powered by
            <span>
              <ImageAlign src={RZPBMLogoLight} alt="BillMe Logo" />
            </span>
          </TextB>
        </div>
      </RenderIf>
      <RenderIf
        active={
          flags?.payNowThroughRzp &&
          (parsedData?.paymentData?.status === 'paid' || isPaymentStatus) &&
          parsedData?.paymentData?.rzpPaymentOrderId &&
          parsedData?.paymentData?.rzpPublicKey
        }>
        <div
          style={{
            margin: '10px',
            backgroundColor: '#65B452',
            padding: '20px',
            borderRadius: '25px',
          }}>
          <Text style={{ marginBottom: '-8px' }}> Your total bill: </Text>
          <$Flexbox>
            <TextValue>
              {' '}
              <FormattedNumber
                value={parsedData?.billAmountData?.netPayableAmount}
                style={`currency`}
                currency={'INR'}
              />
            </TextValue>
          </$Flexbox>
          <Text style={{ marginRight: '-23px', marginTop: '-8px' }}>
            {' '}
            Payment Received. Thank you for shopping and we hope you had a pleasant shopping
            experience.{' '}
          </Text>
          <TextB>
            {' '}
            Powered by
            <span>
              <ImageAlign src={RZPBMLogoLight} alt="BillMe Logo" />
            </span>
          </TextB>
        </div>
      </RenderIf>
    </div>
  );
};

export default Payment;

import KayaNewLoaderImg from '../assets/images/logos/kaya-loader-image.jpg';
import styled from 'styled-components';
import { $Container } from './templates/styles';

const $Line = styled.div`
  position: absolute;
  background-color: #b17358;
  height: 10px;
  z-index: 1007;
  animation: expand-width 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  width: 50px;
  @keyframes expand-width {
    from {
      width: 50px;
    }
    to {
      width: 1000px;
    }
  }
`;
const $LargerLine = styled.div`
  position: absolute;
  background-color: #b17358;
  height: 22px;
  z-index: 1007;
  animation: expand-width 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  width: 50px;
  margin-top: 10px;
  @keyframes expand-width {
    from {
      width: 50px;
    }
    to {
      width: 1000px;
    }
  }
`;

const $ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const KayaLoader = () => {
  return (
    <$Container style={{ height: '100vh' }}>
      <div style={{ width: 'auto' }}>
        <$LargerLine />
        <$Line style={{ top: 37 }} />
        <$ImageContainer>
          <$LargerLine style={{ bottom: 3, right: 0, transform: 'translateX(0px)' }} />
          <$Line style={{ bottom: 30, right: 0, transform: 'translateX(0px)' }} />
          <img
            src={KayaNewLoaderImg}
            alt="Kaya Skin Clinic Logo"
            style={{
              margin: '0 auto',
              maxWidth: '100%',
              maxHeight: '100vh',
              width: 'auto',
              height: 'auto',
            }}
          />
        </$ImageContainer>
      </div>{' '}
    </$Container>
  );
};

export default KayaLoader;

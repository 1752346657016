import React from 'react';

import {
  InstaIconofNyCinema,
  FacebookIconofNyCinema,
  TwitterIconofNyCinema,
  LinkedinIconofNyCinema,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';
import styled, { css } from 'styled-components';

const SocialText = styled.div`
  font-size: 18px;
  margin-top: 20px;
  font-family: Gilroy-Light;
  text-align: center;
  color: white;
  font-weight: 400;
`;
const StyleBox = styled.div`
  background-color: #013b49;
  margin: 20px;
  margin-top: -10px;
  padding-top: 2px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
`;
export const NyCinemaSocial = () => (
  <React.Fragment>
    <StyleBox>
      {/* <SocialText> Follow us on Social Media <br/>Get 10% Discount on F&B items</SocialText> */}
      <$Flexbox
        className="Socialalign"
        justify="space-around"
        padding="16px 0px"
        alignItems="center">
        <a
          href="https://www.instagram.com/nycinemas_official?igsh=eWt6czIwb2x3emwx"
          target="_blank"
          rel="noopener noreferrer">
          <InstaIconofNyCinema style={{ width: '30px', height: '30px' }} />
        </a>
        <a
          href="https://www.facebook.com/nycinemasofficial"
          target="_blank"
          rel="noopener noreferrer">
          <FacebookIconofNyCinema style={{ width: '30px', height: '30px' }} />
        </a>
        <a
          href="https://x.com/ny_cinemas?t=-0oKUXi1v-ksD90XQpE0Dw&s=09"
          target="_blank"
          rel="noopener noreferrer">
          <TwitterIconofNyCinema style={{ width: '33px', height: '30px' }} />
        </a>
        <a
          href="https://www.linkedin.com/company/nycinemas"
          target="_blank"
          rel="noopener noreferrer">
          <LinkedinIconofNyCinema style={{ width: '33px', height: '30px' }} />
        </a>
      </$Flexbox>
    </StyleBox>
  </React.Fragment>
);

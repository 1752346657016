import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
`;

export const $Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  div {
    position: absolute;
    background: #08b7f3;
    opacity: 1;
    border-radius: 50%;
    animation: ${loadingAnimation} 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.7s;
    }
  }
`;

import React from 'react';
import { FBIcon, InstaIcon, PinterestIcon, YoutubeIcon } from '../../assets/images/social';
import { TrentSocials } from '../billView/data';
import { $Flexbox, $SocialLink } from '../../css/styles';
const trentSocials = {
  samoh: [
    {
      link: TrentSocials.samoh.insta,
      component: <InstaIcon />,
    },
    {
      link: TrentSocials.samoh.fb,
      component: <FBIcon />,
    },
    {
      link: TrentSocials.samoh.pint,
      component: <PinterestIcon />,
    },
  ],
  starbazzar: [
    {
      link: TrentSocials.starbazzar.insta,
      component: <InstaIcon />,
    },
    {
      link: TrentSocials.starbazzar.fb,
      component: <FBIcon />,
    },
  ],
  misbu: [
    {
      link: TrentSocials.misbu.insta,
      component: <InstaIcon />,
    },
  ],
  zudio: [
    {
      link: TrentSocials.zudio.insta,
      component: <InstaIcon />,
    },
  ],
  getList: function (isZudio, isStarbazzar, isSamoh, isMisbu) {
    if (isStarbazzar) return this.starbazzar;
    else if (isSamoh) return this.samoh;
    else if (isMisbu) return this.misbu;
    else if (isZudio) return this.zudio;
    else return [];
  },
};

export const TrentSocialList = ({ isZudio, isStarbazzar, isSamoh, isMisbu }) => {
  const list = trentSocials.getList(isZudio, isStarbazzar, isSamoh, isMisbu);
  return (
    <>
      {list.length ? (
        <$Flexbox width="auto" padding="16px 16px 0" justify="space-around" alignItems="center">
          {list.map((social) => (
            <$SocialLink target="_blank" rel="noopener" href={social.link}>
              {social.component}
            </$SocialLink>
          ))}
        </$Flexbox>
      ) : null}
    </>
  );
};

import React from 'react';
import styled from 'styled-components';
import { SemiBoldTag } from '../SemiBoldTag';
import { $EcommBillContainer, $SemiBold, $Flexbox, TruncateText } from '../../css/styles';

import { Dash, DevyaniTable } from './styles';
import { getDateAndTime } from '../../utils/datetime';
import { renderAmount, getPaymentMode } from '../../utils/amount';
import RenderIf from '../RenderIf';

const checkIfProductTaxPresent = (items) =>
  !!items &&
  !!items.length &&
  !!items.filter((product) => !!product?.taxes && !!Object.keys(product?.taxes).length).length;

const thickDash = {
  color: 'hsla(0, 0%, 0%, 0.25)',

  type: 'thick',
};

const thinDash = {
  color: 'hsla(0, 0%, 0%, 0.5)',
};

const $NykaaTable = styled(DevyaniTable)`
  border-spacing: 4px;
  table-layout: fixed;
`;

const $TaxesTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-top: 8px;
  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .center {
    text-align: center;
  }

  thead {
    th,
    td {
      border: 1px dashed black;
    }
  }

  tbody {
    td {
      border-right: 1px dashed black;
      padding: 4px;
    }
    td:first-child {
      border-left: 1px dashed black;
    }
    tr:last-child {
      td {
        border-bottom: 1px dashed black;
      }
    }
  }
`;

const $Terms = styled.div`
  padding: 16px;
  p {
    margin-bottom: 4px;
    text-align: center;
    a {
      text-decoration: none;
      color: #fc2779;
    }
  }
`;

const Nykaa = React.forwardRef(
  (
    {
      customData: {
        companyData = {},
        storeData = {},
        transactionalData = {},
        customerData = {},
        billAmountData = {},
        loyaltyData = {},
        paymentData = {},
        items = {},
        taxesData = {},
        billFooterData = {},
        other = {},
      } = {},
    },
    ref,
  ) => {
    const { date, time } = getDateAndTime(transactionalData?.invoiceDate);

    const isProductTaxesPresent = checkIfProductTaxPresent(items);

    return (
      <$EcommBillContainer ref={ref} id="nykaa-ecom-bill">
        <div style={{ textAlign: 'center' }}>
          {!!companyData?.name && (
            <h4 className="header" style={{ textTransform: 'uppercase' }}>
              {companyData?.name}
            </h4>
          )}
          {!!storeData?.storeAddress && (
            <p style={{ maxWidth: '250px', margin: '0px auto' }}>
              <$SemiBold>{storeData?.storeAddress}</$SemiBold>
            </p>
          )}
          {!!transactionalData?.invoiceType && (
            <h4 className="header" style={{ textTransform: 'uppercase' }}>
              {transactionalData?.invoiceType}
            </h4>
          )}
        </div>

        <Dash {...thickDash} />

        <div style={{ textAlign: 'center' }}>
          <SemiBoldTag label="GSTIN" value={storeData?.storeGstNumber} />
          <SemiBoldTag label="CIN" value={companyData?.cin} />
        </div>

        <Dash {...thickDash} />

        <div style={{ margin: '0px 8px' }}>
          <SemiBoldTag label="Invoice No." value={transactionalData?.invoiceNumber} />

          <SemiBoldTag label="Cashier Name" value={transactionalData?.cashierName} />

          <SemiBoldTag label="Order No." value={transactionalData?.orderNumber} />

          <SemiBoldTag label="Date & Time" value={`${date} ${time}`} />
        </div>

        <Dash {...thickDash} />

        <div style={{ margin: '0px 8px' }}>
          {(!!customerData?.firstName || !!customerData?.lastName) && (
            <SemiBoldTag
              label="Customer Name"
              value={`${customerData?.firstName || ''} ${customerData?.lastName || ''}`}
            />
          )}
          <SemiBoldTag label="Customer Ph" value={customerData?.phone} />
          <SemiBoldTag label="Customer Email" value={customerData?.email} />
          <SemiBoldTag label="Customer ID" value={customerData?.customerId} />
          <SemiBoldTag label="Customer GST" value={customerData?.custGstNumber} />
        </div>

        <Dash {...thickDash} />

        <$NykaaTable>
          <tbody>
            <tr>
              <th>SKU Code</th>
              <th>QTY</th>
              <th>Unit Amt</th>
            </tr>
            <tr>
              <th>Product Name</th>
              <th>Discount</th>
              <th>Total Amt</th>
            </tr>
            <tr>
              <th>Product Code</th>
              <th></th>
              <th></th>
            </tr>
          </tbody>
        </$NykaaTable>

        {!!items &&
          items.length &&
          items?.map((product) => (
            <React.Fragment key={product?.productCode}>
              <Dash {...thinDash} />

              <$NykaaTable>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'top' }}>{product?.productUid}</td>
                    <td style={{ verticalAlign: 'top' }}>{product?.quantity}</td>
                    <td style={{ verticalAlign: 'top' }}>
                      {renderAmount(product?.price, undefined, 2)}
                    </td>
                  </tr>
                  <tr>
                    <td>{product?.name}</td>
                    <td>{renderAmount(product?.discount)}</td>
                    <td>{renderAmount(product?.total, undefined, 2)}</td>
                  </tr>
                  <tr>
                    <td>{product?.productCode}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </$NykaaTable>
            </React.Fragment>
          ))}

        <Dash {...thinDash} />

        <$Flexbox width="auto" justify="space-between" padding="0px 4px">
          <SemiBoldTag
            label="TOTAL QTY"
            value={billAmountData?.totalQty}
            paraStyle={{ margin: 0 }}
          />

          <SemiBoldTag
            label="SUBTOTAL"
            value={renderAmount(billAmountData?.subTotal, undefined, 2)}
            paraStyle={{ margin: 0 }}
          />
        </$Flexbox>

        <Dash {...thickDash} />

        <$NykaaTable>
          <tbody>
            <RenderIf active={billAmountData?.roundupAmount}>
              <tr>
                <td>Round up Amount</td>
                <td>{renderAmount(billAmountData?.roundupAmount, undefined, 2)}</td>
              </tr>
            </RenderIf>
            <RenderIf active={billAmountData?.totalDiscount}>
              <tr>
                <td>Total Discount</td>
                <td>{renderAmount(billAmountData?.totalDiscount, undefined, 2)}</td>
              </tr>
            </RenderIf>
            <RenderIf active={billAmountData?.netPayableAmount}>
              <tr>
                <td>Net Payable</td>
                <td>{renderAmount(billAmountData?.netPayableAmount, undefined, 2)}</td>
              </tr>
            </RenderIf>
            <RenderIf active={loyaltyData?.pointsRedeemed}>
              <tr>
                <td>Reward Points Redeemed</td>
                <td>{renderAmount(loyaltyData?.pointsRedeemed, undefined, 2)}</td>
              </tr>
            </RenderIf>
            <RenderIf active={other?.otherDiscount}>
              <tr>
                <td>Other Discount</td>
                <td>{renderAmount(other?.otherDiscount, undefined, 2)}</td>
              </tr>
            </RenderIf>
          </tbody>
        </$NykaaTable>

        <Dash {...thickDash} />

        {other?.couponDetails?.length > 0 && (
          <>
            <h4 className="header" style={{ textTransform: 'uppercase', textAlign: 'center' }}>
              Coupon Details
            </h4>
            <table style={{ ...tableStyling }}>
              {other?.couponDetails?.map((coup, i) => (
                <tr key={coup?.couponCode + i}>
                  <td>
                    <b>{coup?.couponCode}</b>
                    <br />
                    {coup?.description}
                  </td>
                  <RightAlignedTd>
                    {!!coup?.couponAmnt && (
                      <>
                        {renderAmount(coup?.couponAmnt)}
                        <br />
                      </>
                    )}
                    {coup?.couponAmntPercent ? `${coup?.couponAmntPercent}%` : ''}
                  </RightAlignedTd>
                </tr>
              ))}
            </table>
            <Dash {...thickDash} />
          </>
        )}

        <$Flexbox width="auto" padding="0px 4px">
          <p style={{ flexBasis: '40%' }}>
            <$SemiBold style={{ width: '160px' }}> Payment Mode:</$SemiBold>
          </p>

          <div style={{ width: '100%' }}>
            {!!paymentData.mode &&
              paymentData?.mode.length &&
              paymentData?.mode.map((item) =>
                Object.keys(item).map((key, i) => (
                  <$Flexbox justify="space-between" padding="0" key={i}>
                    <TruncateText as="p" maxWidth="150px">
                      {getPaymentMode(key)}
                    </TruncateText>

                    <p>{renderAmount(Object.values(item)[i], undefined, 2)}</p>
                  </$Flexbox>
                )),
              )}
          </div>
        </$Flexbox>

        {!!taxesData?.totalTax && (
          <>
            <Dash {...thickDash} />
            <$NykaaTable>
              <tbody>
                <tr>
                  <td>Total Tax</td>
                  <td>{renderAmount(taxesData?.totalTax, undefined, 2)}</td>
                </tr>
              </tbody>
            </$NykaaTable>
          </>
        )}

        {isProductTaxesPresent && (
          <$TaxesTable>
            <thead>
              <tr>
                <td rowSpan={2} className="center">
                  HSN Code
                </td>
                <td colSpan={2} className="center">
                  CGST
                </td>
                <td colSpan={2} className="center">
                  SGST
                </td>
              </tr>
              <tr>
                <td className="center"> Rate</td>
                <td className="center"> Amount</td>
                <td className="center"> Rate</td>
                <td className="center"> Amount</td>
              </tr>
            </thead>
            <tbody>
              {items.map((product, i) => (
                <tr key={`${product?.hsnCode}${i}`}>
                  <td>{product?.hsnCode}</td>
                  <td className="right">
                    {renderAmount(product?.taxes?.cgstPercent, 'percent', 2)}
                  </td>
                  <td className="right">{renderAmount(product?.taxes?.cgst, undefined, 2)}</td>
                  <td className="right">
                    {renderAmount(product?.taxes?.sgstPercent, 'percent', 2)}
                  </td>
                  <td className="right">{renderAmount(product?.taxes?.sgst, undefined, 2)}</td>
                </tr>
              ))}
            </tbody>
          </$TaxesTable>
        )}

        <$Terms>
          {billFooterData?.disclaimer
            ?.replace(
              'www.nykaa.com',
              `<a href="https://www.nykaa.com" target="_blank" rel="noopener">www.nykaa.com</a>`,
            )
            ?.split('\n')
            ?.map((li) => (
              <p key={li} dangerouslySetInnerHTML={{ __html: li }} />
            ))}
        </$Terms>
      </$EcommBillContainer>
    );
  },
);

const tableStyling = {
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: '4px',
  tableLayout: 'fixed',
  margin: '16px 0px',
  fontSize: '12px',
};

const taxesTable = {
  width: '100%',
  borderSpacing: '0px',
  marginTop: '8px',
  fontSize: '12px',
};

const terms = {
  padding: '16px',
  textAlign: 'center',
};

const hrStyling = {
  height: '1px',
  margin: '4px 0px',
  color: '#ddd',
};

const Line = () => <hr style={{ ...hrStyling }} />;

const borderedData = {
  border: '1px solid #ddd',
};

const BorderedData = ({ children, ...rest }) => (
  <td {...rest} style={{ textAlign: 'center', ...borderedData }}>
    {children}
  </td>
);

const RightAlignedTd = ({ children, ...rest }) => (
  <td style={{ textAlign: 'right', fontSize: '12px' }} {...rest}>
    {children}
  </td>
);

export const NykaaDownloadBillPdfTemplate = ({
  customData: {
    companyData = {},
    storeData = {},
    transactionalData = {},
    customerData = {},
    billAmountData = {},
    paymentData = {},
    items = {},
    taxesData = {},
    billFooterData = {},
  } = {},
}) => {
  const { date, time } = getDateAndTime(transactionalData?.invoiceDate);

  const isProductTaxesPresent = checkIfProductTaxPresent(items);

  return (
    <div style={{ padding: '8px 32px', fontSize: '12px' }}>
      <div style={{ textAlign: 'center' }}>
        {!!companyData?.name && (
          <h4 className="header" style={{ textTransform: 'uppercase' }}>
            {companyData?.name}
          </h4>
        )}

        {!!storeData?.storeAddress && (
          <p style={{ maxWidth: '250px', margin: '0px auto' }}>
            <$SemiBold>{storeData?.storeAddress}</$SemiBold>
          </p>
        )}

        {!!transactionalData?.invoiceType && (
          <h4 className="header" style={{ textTransform: 'uppercase' }}>
            {transactionalData?.invoiceType}
          </h4>
        )}
      </div>

      <Line />

      <div style={{ textAlign: 'center' }}>
        <SemiBoldTag label="GSTIN" value={storeData?.storeGstNumber} />
        <SemiBoldTag label="CIN" value={companyData?.cin} />
      </div>

      <Line />

      <div style={{ margin: '0px 8px' }}>
        <SemiBoldTag label="Invoice No." value={transactionalData?.invoiceNumber} />

        <SemiBoldTag label="Cashier Name" value={transactionalData?.cashierName} />

        <SemiBoldTag label="Order No." value={transactionalData?.orderNumber} />
        <SemiBoldTag label="Date & Time" value={`${date} ${time}`} />
      </div>

      <Line />

      <div style={{ margin: '0px 8px' }}>
        {(!!customerData?.firstName || !!customerData?.lastName) && (
          <SemiBoldTag
            label="Customer Name"
            value={`${customerData?.firstName || ''} ${customerData?.lastName || ''}`}
          />
        )}
        <SemiBoldTag label="Customer Ph" value={customerData?.phone} />
        <SemiBoldTag label="Customer Email" value={customerData?.email} />
        <SemiBoldTag label="Customer ID" value={customerData?.customerId} />
        <SemiBoldTag label="Customer GST" value={customerData?.custGstNumber} />
      </div>

      <Line />

      <table style={{ ...tableStyling }}>
        <tr>
          <th style={{ textAlign: 'left' }}>SKU Code</th>
          <th>QTY</th>
          <th style={{ textAlign: 'right' }}>Unit Amt</th>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>Product Name</th>
          <th>Discount</th>
          <th style={{ textAlign: 'right' }}>Total Amt</th>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>Product Code</th>
          <th></th>
          <th></th>
        </tr>
      </table>

      {!!items &&
        items.length &&
        items?.map((product) => (
          <>
            <Line />
            <table style={{ ...tableStyling }}>
              <tr>
                <td style={{ verticalAlign: 'top' }}>{product?.productUid}</td>
                <td style={{ textAlign: 'center', verticalAlign: 'top' }}>{product?.quantity}</td>
                <RightAlignedTd style={{ verticalAlign: 'top' }}>
                  {renderAmount(product?.price)}
                </RightAlignedTd>
              </tr>
              <tr>
                <td>{product?.name}</td>
                <td style={{ textAlign: 'center' }}>{renderAmount(-product?.discount)}</td>
                <RightAlignedTd>{renderAmount(product?.total)}</RightAlignedTd>
              </tr>
              <tr>
                <td>{product?.productCode}</td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </>
        ))}

      <Line />

      <table
        style={{
          width: '100%',
          padding: '0px 8px',
          margin: '16px 0px',
          fontSize: '12px',
        }}>
        <tr>
          <td style={{ margin: 0 }}>
            <strong>TOTAL QTY</strong>: {billAmountData?.totalQty}
          </td>
          <RightAlignedTd style={{ margin: 0 }}>
            <strong>SUBTOTAL</strong>: {renderAmount(billAmountData?.subTotal)}
          </RightAlignedTd>
        </tr>
      </table>

      <Line />

      <table style={{ ...tableStyling }}>
        {!!billAmountData?.roundupAmount && (
          <tr>
            <td>Round up Amount</td>
            <RightAlignedTd>{renderAmount(billAmountData?.roundupAmount)}</RightAlignedTd>
          </tr>
        )}

        {!!billAmountData?.totalDiscount && (
          <tr>
            <td>Total Discount</td>

            <RightAlignedTd>{renderAmount(billAmountData?.totalDiscount)}</RightAlignedTd>
          </tr>
        )}

        {!!billAmountData?.netPayableAmount && (
          <tr>
            <td>Net Payable</td>

            <RightAlignedTd>{renderAmount(billAmountData?.netPayableAmount)}</RightAlignedTd>
          </tr>
        )}
      </table>

      <Line />

      <div
        style={{
          display: 'flex',
          padding: '0px 8px',
          fontSize: '12px',
        }}>
        <p style={{ flexBasis: '40%' }}>
          <span style={{ width: '160px', fontWeight: 500 }}> Payment Mode:</span>
        </p>

        <div style={{ width: '100%' }}>
          {!!paymentData.mode &&
            paymentData?.mode.length &&
            paymentData?.mode.map((item) =>
              Object.keys(item).map((key, i) => (
                <table
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 8px',
                  }}>
                  <tr>
                    <td
                      style={{
                        maxWidth: '150px',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginBottom: 0,
                        width: '100%',
                        fontSize: '12px',
                      }}>
                      {getPaymentMode(key)}
                    </td>

                    <RightAlignedTd>{renderAmount(Object.values(item)[i])}</RightAlignedTd>
                  </tr>
                </table>
              )),
            )}
        </div>
      </div>

      {!!taxesData?.totalTax && (
        <>
          <Line />

          <table style={{ ...tableStyling }}>
            <tr>
              <td>Total Tax</td>

              <RightAlignedTd>{renderAmount(taxesData?.totalTax)}</RightAlignedTd>
            </tr>
          </table>
        </>
      )}

      {isProductTaxesPresent && (
        <table style={{ ...taxesTable }}>
          <tbody>
            <tr>
              <BorderedData rowSpan={2}> HSN Code</BorderedData>
              <BorderedData colSpan={2}> CGST</BorderedData>
              <BorderedData colSpan={2}> SGST</BorderedData>
            </tr>

            <tr>
              <BorderedData>Rate</BorderedData>
              <BorderedData>Amount</BorderedData>
              <BorderedData>Rate</BorderedData>
              <BorderedData>Amount</BorderedData>
            </tr>

            {items.map((product) => (
              <tr>
                <BorderedData>{product?.hsnCode}</BorderedData>
                <RightAlignedTd style={{ ...borderedData }}>
                  {renderAmount(product?.taxes?.cgstPercent, 'percent')}
                </RightAlignedTd>
                <RightAlignedTd style={{ ...borderedData }}>
                  {renderAmount(product?.taxes?.cgst)}
                </RightAlignedTd>
                <RightAlignedTd style={{ ...borderedData }}>
                  {renderAmount(product?.taxes?.sgstPercent, 'percent')}
                </RightAlignedTd>

                <RightAlignedTd style={{ ...borderedData }}>
                  {renderAmount(product?.taxes?.sgst)}
                </RightAlignedTd>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div styles={terms}>
        {billFooterData?.disclaimer
          ?.replace(
            'www.nykaa.com',
            `<a href="https://www.nykaa.com" target="_blank" rel="noopener">www.nykaa.com</a>`,
          )
          ?.split('\n')
          ?.map((li) => (
            <p key={li} dangerouslySetInnerHTML={{ __html: li }} />
          ))}
      </div>
    </div>
  );
};

export default Nykaa;

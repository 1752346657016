import React from 'react';
import axios from 'axios';

import Pdf from '../templates/PdfTemplate';
import { useToasts } from '../Toast';
import { CarouselSlider } from './CarouselSlider';
import {
  $CampaignContainer,
  $Coupon,
  $PdfContainer,
  $SIBContainer,
  $YoutubeContainer,
  $MarginContainerWithBorder,
  $CarouselContainer,
} from './campaigns.styled';

import { ReactComponent as CopyIcon } from '../../assets/images/copy.svg';
import { campaignTypes, getCampaignMaxHeight } from './constants';
import { config } from '../../config';
import { useDebouncedEffect } from '../../utils';
import { matchValueOrDefault } from '../../utils/regexHelper';
import { $Block, $Div } from '../templates/styles';
import { $POBButton } from '../../css/styles';

export const Campaign = ({
  billUid,
  campaign,
  couponCode,
  surveyData,
  type,
  isButtonPresent,
  surveyLink,
  isPopupOverBillModalOpen,
  handlePOBButtonClick,
  campaignDetailsPOB,
  isManyavar,
  isRzpFtx25,
}) => {
  const { toast } = useToasts();
  const [isViewed, setIsViewed] = React.useState(false);

  if (campaign?.assetsDetails?.length > 0) {
    campaign.assetsDetails = campaign.assetsDetails.map((ast) => {
      if (ast?.surveyId) {
        ast.redirectUrl =
          'https://' +
          (surveyData?.[campaign?.campaignId]?.filter((item) => item.surveyId === ast?.surveyId)[0]
            ?.link || '');
      }
      return ast;
    });
  }

  const asset = campaign?.assetsDetails ? campaign?.assetsDetails[0] : {};

  const isImageOrGif = ['gif', 'image'].includes(campaign?.componentType);
  const isImageOrGifWithRedirect =
    ['gif', 'image'].includes(campaign?.componentType) && asset?.redirectUrl;

  const trackActivity = (e, assetid) => {
    // avoid tracking for sib
    if (['sib'].includes(campaign?.componentType)) return;
    // avoid tracking for non popup campaigns when popup is open.
    if (isPopupOverBillModalOpen && type !== campaignTypes.popupOverBill) return;

    //identitfy activity type
    const activityType = e ? 'clicked' : 'seen';
    if (e && type === campaignTypes.sellBelowBill) e.preventDefault();

    const href = e ? e.target.href : '';
    // avoid tracking clicks for un-supported elememts
    if (activityType === 'clicked' && !['A', 'BUTTON'].includes(e.target.tagName)) {
      href && type === campaignTypes.sellBelowBill && window.open(href);
      return;
    }

    const assetId = assetid
      ? assetid
      : campaign?.componentType === 'carousel' && activityType === 'clicked'
        ? e?.target.getAttribute('assetid')
        : campaign?.assetsDetails
          ? campaign?.assetsDetails[0]?.assetId
          : '';

    const localStorageId = assetId
      ? assetId + billUid + campaign.campaignId
      : campaign.campaignId + billUid;
    const status = localStorage.getItem(localStorageId);
    // avoid repeat seens
    if (
      ['clicked', 'seen'].includes(status) &&
      activityType === 'seen' &&
      type !== campaignTypes.sellBelowBill
    ) {
      return;
    }
    //avoid repeat clicks
    if (e && e.target.tagName === 'BUTTON' && status === 'clicked') {
      return;
    }
    const body = {
      billUid,
      channel: type,
      campaignStats: getCampaignStats(activityType, assetId, e),
    };
    localStorage.setItem(localStorageId, activityType);
    axios
      .patch(`${config.host}/campaign/${campaign.campaignId}/user-stats`, body)
      .then(() => {
        localStorage.setItem(localStorageId, activityType);
        href && type === campaignTypes.sellBelowBill && window.open(href);
      })
      .catch((err) => {
        console.error(err);
        href && type === campaignTypes.sellBelowBill && window.open(href);
      });
  };

  const isInView = () => {
    const elem = document.getElementById(campaign?.campaignId);
    const box = elem?.getBoundingClientRect();
    const isIn = box?.top + 100 + box?.height < window?.innerHeight && box?.bottom >= 0;
    if (isIn) setIsViewed(isIn);
  };

  useDebouncedEffect(() => isViewed && trackActivity(), [isViewed], 100);

  useDebouncedEffect(() => {
    const elem = document.getElementById(campaign.campaignId);
    if (elem) {
      elem.addEventListener('click', trackActivity);
      window.addEventListener('scroll', isInView);
      isInView();
      return () => {
        elem.removeEventListener('click', trackActivity);
        window.removeEventListener('scroll', isInView);
      };
    }
  }, []);

  const copyToClipboard = (text) => {
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toast('Coupon code has been copied to the clipboard', 'success');
  };
  const getCampaignStats = (activityType, assetId, e) => {
    const campaignStats = {};
    if (
      (activityType === 'clicked' &&
        (e.target.tagName === 'BUTTON' || type === campaignTypes.sellBelowBill)) ||
      campaign?.componentType === 'youtube'
    ) {
      campaignStats['links'] = [
        {
          link:
            campaign?.componentType === 'youtube'
              ? getYoutubeLink(true)
              : e.target.tagName === 'BUTTON'
                ? campaignDetailsPOB?.setting?.btnDetails?.link
                : [e.target.href][0],
          clicked: campaign?.componentType !== 'youtube',
          seen: campaign?.componentType === 'youtube',
          timeStamp: new Date().toISOString(),
        },
      ];
    } else {
      campaignStats['media'] = [
        {
          seen: true,
          timeStamp: new Date().toISOString(),
          clicked: activityType === 'clicked',
          id: assetId,
        },
      ];
    }
    return campaignStats;
  };

  const getCouponCode = (ast = asset) => {
    if (ast?.couponVisibility && couponCode)
      return (
        <$Coupon
          position={campaign?.couponPosition}
          color={campaign?.couponFontColor}
          fontSize={campaign?.couponFontSize}
          fontFamily={campaign?.couponFontTypeface}
          onClick={() => copyToClipboard(couponCode)}>
          <CopyIcon />
          {couponCode}
        </$Coupon>
      );
  };

  const getYoutubeLink = (linkOnly = false) => {
    if (asset?.key) {
      const youtubeLink = asset?.key;
      let srcLink = '';
      if (linkOnly) {
        const regex = /src="([\w.\/:-]+)"/gi;
        return matchValueOrDefault('', asset.key, regex);
      } else {
        srcLink = youtubeLink.substring(
          youtubeLink.indexOf('src="'),
          youtubeLink.indexOf('" title') + 1,
        );
      }

      const autoplay = campaign?.autoplay === 'on' ? 1 : 0;
      const mute = campaign?.mute === 'on' ? 1 : 0;

      const toReplace = srcLink.substr(srcLink.length - 5, 4);
      const toReplaceWith = `${toReplace}?autoplay=${autoplay}&mute=${mute}`;

      const attributeAddedLink = youtubeLink.replace(toReplace, toReplaceWith);

      return attributeAddedLink;
    }

    return '';
  };

  const getCampaign = () => {
    switch (campaign?.componentType) {
      case 'pdf':
        return (
          <$PdfContainer id={campaign?.campaignId} className="media-no-print">
            <Pdf url={asset?.fileLocation} size={400} />
          </$PdfContainer>
        );

      case 'carousel': {
        return (
          <$CarouselContainer id={campaign?.campaignId} className="media-no-print">
            <CarouselSlider
              assets={campaign.assetsDetails}
              getCouponCode={getCouponCode}
              maxHeight={getCampaignMaxHeight(campaign?.type)}
              trackActivity={trackActivity}
              isViewed={isViewed}
            />
          </$CarouselContainer>
        );
      }

      case 'sib': {
        return (
          <$SIBContainer
            rel="noopener"
            target="_blank"
            href={surveyLink}
            id={campaign?.campaignId}
            className={isRzpFtx25 ? 'footermargin media-no-print' : 'media-no-print'}>
            <img src={campaign?.buttonImg} alt="Survey Link" />
            {getCouponCode({ couponVisibility: true })}
          </$SIBContainer>
        );
      }

      case 'youtube': {
        return (
          <$Block>
            <$CampaignContainer
              id={campaign?.campaignId}
              maxHeight={getCampaignMaxHeight(campaign?.type)}
              className="media-no-print">
              {campaign?.componentType === 'youtube' && (
                <$YoutubeContainer dangerouslySetInnerHTML={{ __html: getYoutubeLink() }} />
              )}
              {getCouponCode()}
            </$CampaignContainer>
          </$Block>
        );
      }

      default:
        return (
          <$Block padding="12px" backgroundColor={isManyavar ? '#4C2008' : ''}>
            <$CampaignContainer
              id={campaign?.campaignId}
              maxHeight={getCampaignMaxHeight(campaign?.type)}
              className="media-no-print">
              {isImageOrGif && <img src={asset?.fileLocation} />}
              {isImageOrGifWithRedirect && (
                <a target="_blank" rel="noopener" href={asset?.redirectUrl} />
              )}
              {campaign?.componentType === 'video' && (
                <video
                  loop
                  controls
                  src={asset?.fileLocation}
                  autoPlay={campaign?.autoplay === 'on'}
                  muted={campaign?.mute === 'on'}>
                  <source src={asset?.fileLocation} />
                </video>
              )}
              {campaign?.componentType === 'youtube' && (
                <$YoutubeContainer dangerouslySetInnerHTML={{ __html: getYoutubeLink() }} />
              )}
              {getCouponCode()}
            </$CampaignContainer>
          </$Block>
        );
    }
  };

  if (
    type !== campaignTypes.bannerInbill &&
    type !== campaignTypes.surveyInBill &&
    campaign?.componentType !== 'carousel'
  ) {
    return (
      <>
        <$MarginContainerWithBorder
          isPOB={type === campaignTypes.popupOverBill}
          isButtonPresent={isButtonPresent}
          marginLeft={isRzpFtx25 ? '10px' : '0px'}
          marginRight={isRzpFtx25 ? '10px' : '0px'}
          marginBottom={isRzpFtx25 ? '10px' : '0px'}
          id={
            campaign.componentType === 'youtube'
              ? campaign?.campaignId
              : 'contailer_' + campaign?.campaignId
          }
          className="media-no-print">
          {getCampaign()}
        </$MarginContainerWithBorder>
        {type === campaignTypes.popupOverBill && !!campaignDetailsPOB?.setting?.btnDetails?.txt && (
          <$POBButton
            bgColor={campaignDetailsPOB?.setting?.btnDetails?.bgColor}
            txtColor={campaignDetailsPOB?.setting?.btnDetails?.txtColor}
            onClick={(e) => {
              handlePOBButtonClick(e);
              trackActivity(e);
            }}
            style={{
              color: campaignDetailsPOB?.setting?.btnDetails?.txtColor,
              backgroundColor: campaignDetailsPOB?.setting?.btnDetails?.bgColor,
            }}>
            {campaignDetailsPOB?.setting?.btnDetails?.txt}
          </$POBButton>
        )}
      </>
    );
  } else {
    return getCampaign();
  }
};

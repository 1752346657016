const NYBOptionTitles = [
  {
    title: 'I cannot see my bill',
    step: 0,
    option: true,
  },
  {
    title: 'This is not my bill',
    step: 1,
    option: true,
  },
  {
    title: 'There are mistakes in the bill',
    step: 2,
    option: true,
    feedback: true,
  },
  {
    title: 'I want to give feedback to the store',
    step: 3,
    option: true,
    feedback: true,
  },
  {
    title: 'There are mistakes in the bill',
    step: 4,
    option: false,
    feedback: true,
  },
  {
    title: 'I never visited the store',
    step: 5,
    option: false,
  },
  {
    title: 'I never visited the store',
    step: 6,
  },
];

const secondaryOptions = [
  {
    title: 'There are mistakes in the bill',
    step: 4,
  },
  {
    title: 'I never visited the store',
    step: 5,
  },
];

export { NYBOptionTitles, secondaryOptions };

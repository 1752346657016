import styled, { css } from 'styled-components';

import { BillMaxWidth } from '../constants';
import { $Button } from '../../css/styles';

export const $NYBContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  max-width: ${BillMaxWidth};
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;

  ${({ isBestSeller }) =>
    isBestSeller &&
    css`
      max-width: unset;
      left: 0;
    `}
`;

export const $NYBContent = styled.div`
  position: absolute;
  width: 80%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 26px -3px rgba(0, 0, 0, 0.75);
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  opacity: 1;

  ${({ isBestSeller }) =>
    isBestSeller &&
    css`
      max-width: 400px;
    `}
`;

export const $NYBHeader = styled.div`
  text-align: center;
  padding: 12px 0;
  background-color: #e3f4f7;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  color: black;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 50px;
  height: 100%;

  p {
    margin: 0;
    color: #000;
    font-size: 15px;
    font-weight: 900;
  }
`;
export const $NYBOptionBlock = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const $NYBOption = styled.li`
  padding: 6px 0;
  margin: 0 18px;
  border-bottom: 1px dashed #615e5e;
  color: #162b27;

  &:last-child {
    border: none;
  }
`;

export const $POBCloseButton = styled($Button)`
  position: absolute;
  top: -25px;
  right: -5px;
`;

export const $Radio = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  cursor: pointer;
  input {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  label {
    cursor: pointer;
    font-size: 14px;
    margin: 0 16px 0;
    color: #000;
  }
`;

export const $NYBControls = styled.div`
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  display: flex;
  border-radius: 20px;
  box-shadow: 0px -4px 51px -17px rgba(0, 0, 0, 0.75);
  .next {
    background-color: #00be18;
    color: #fff;
    border-bottom-right-radius: 20px;
  }

  .cancel {
    color: #ff7777;
    background-color: #fff;
    border-bottom-left-radius: 20px;
  }

  .delete {
    color: #fff;
    background-color: #ff7777;
    border-bottom-right-radius: 20px;
  }

  .last-step {
    border-bottom-left-radius: 20px;
  }
`;

export const $NYBControlItem = styled.button`
  flex: 1;
  padding: 12px 0;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
`;

export const $InfoText = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 10px 40px 0;
  text-align: center;
`;

export const $Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 238px;
`;

export const $SVG = styled.div`
  width: ${({ width }) => width || '50px'};
  svg {
    width: 100%;
    height: 100%;
  }
`;

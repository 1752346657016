import React from 'react';

import {
  MarcaFacebookIcon,
  MarcaInstagramIcon,
  BestSellerWebIcon,
  WhatsAppIcon,
  BestSellerCallIcon,
  YoutubeIcon,
  BestSellerEmailIcon,
} from '../../assets/images/social';
import FindStores from '../../assets/images/best-find-stores.png';
import { $Flexbox } from '../../css/styles';

export const BestSellerSocial = () => (
  <>
    <a href="https://www.jackjones.in/store-locator" target="_blank" rel="noopener noreferrer">
      <img src={FindStores} style={{ maxWidth: '100%', padding: '0 16px' }} />
    </a>
    <$Flexbox justify="center" gap="48px">
      <a href="https://www.facebook.com/JackJonesIndia/" target="_blank" rel="noopener noreferrer">
        <MarcaFacebookIcon style={{ width: '35px', height: '30px' }} />
      </a>
      <a href="https://www.instagram.com/jackjonesindia" target="_blank" rel="noopener noreferrer">
        <MarcaInstagramIcon style={{ width: '35px', height: '30px' }} />
      </a>
      <a href="https://wa.me//+917710000511" target="_blank" rel="noopener noreferrer">
        <WhatsAppIcon style={{ width: '35px', height: '30px' }} />
      </a>
      <a
        href="https://www.youtube.com/user/JackJonesIndia"
        target="_blank"
        rel="noopener noreferrer">
        <YoutubeIcon style={{ width: '35px', height: '30px' }} />
      </a>
    </$Flexbox>
  </>
);

export const BestSellerOnline = () => (
  <$Flexbox justify="space-around">
    <$Flexbox
      as="a"
      width="70px !important"
      href="tel:18002660005"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <BestSellerCallIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>24*7 Toll Free</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      width="70px !important"
      href="mailto:help@jackjones.in"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <BestSellerEmailIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Email Us</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      width="70px !important"
      href="https://www.jackjones.in/"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <BestSellerWebIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Website</p>
    </$Flexbox>
  </$Flexbox>
);

/* eslint-disable */

type RegexType = string | RegExp;
import { KeyValue } from './types';
type Placeholder = Array<any> | null;

const matchAll = (str: string, regex: RegexType, modifiers?: string, position?: number) => {
  const regexNew = new RegExp(regex, modifiers);
  const res: any = [];
  let m: any;
  let arrayPosition: number = 1;
  if (position !== undefined) arrayPosition = position;
  if (regexNew.global) {
    while ((m = regexNew.exec(str))) {
      res.push(m[arrayPosition]);
    }
  } else if ((m = regexNew.exec(str))) {
    res.push(m[arrayPosition]);
  }
  return res;
};

const matchAllWithoutPosition = (str: string, regex: RegexType, modifiers: string) => {
  regex = new RegExp(regex, modifiers);
  const res: any = [];
  let m;

  if (regex.global) {
    while ((m = regex.exec(str))) {
      res.push(m);
    }
  } else if ((m = regex.exec(str))) {
    res.push(m);
  }
  return res;
};

const filterUnique = (...args: any[]) => {
  const field = matchAll(args[0], args[1], args?.[2], args?.[3]);
  return field && field.length > 0
    ? [...new Set(field.map((x) => (x ? x.trim().replace(/[\s]+/g, ' ') : '')))].join(', ')
    : '';
};

const matchValueOrDefaultWithoutPosition = (...args: any[]) => {
  const match = matchAllWithoutPosition(args[0], args[1], args?.[2]);

  const numberOfSeats = match.length / 3;

  let seats = '';

  for (let i = 0; i < numberOfSeats; i++) {
    seats = i === 0 ? match[i * 3][1] : seats + ', ' + match[i * 3][1];
  }

  return seats.replace(/[\s]{3,}/, '');
};
const matchValueOrDefault = (defaultValue: any, ...args: any[]) => {
  const match = matchAll(args[0], args[1], args?.[2], args?.[3]);
  return match && match[0] ? match[0].replace(/[\s]{3,}/, '') : defaultValue;
};
export {
  matchAll,
  matchAllWithoutPosition,
  filterUnique,
  matchValueOrDefault,
  matchValueOrDefaultWithoutPosition,
};

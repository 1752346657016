import { createGlobalStyle } from 'styled-components';
import { BlurWhiteOverlay } from '../../components/templates/styles';

export const GlobalHTMLStyle = createGlobalStyle`
    html{
        overflow: hidden;
    }
`;

export const MediaPrintStyle = createGlobalStyle`
    @media print {
        .media-no-print{
            display: none;
        }
    }
`;

import React from 'react';
import '../../css/loyalty.scss';
import QRCode from 'qrcode.react';
import { FanzImageBase64, ZudioImageBase64 } from '../billView/data';
import { $Flexbox } from '../../css/styles';

const LoyaltyCard = ({ brandLogo, user, handleDownloadSpecificHTML }) => {
  return (
    <div className="loyalty-container" id="loyalty-container">
      <$Flexbox
        alignItems="center"
        width="auto"
        className="div-loyalty"
        id="div-loyalty"
        direction="column"
        justify="space-around">
        &nbsp;
        <img src={FanzImageBase64} alt="FanZ Code" />
        <QRCode size={200} value={user} />
        <img src={ZudioImageBase64} alt="Zudio" />
      </$Flexbox>
      <div
        onClick={(e) => handleDownloadSpecificHTML('loyalty-container')}
        id="loyalty-download"
        className="loyalty-download">
        Download your fanz code
      </div>
    </div>
  );
};

export default LoyaltyCard;

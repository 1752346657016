import React from 'react';

import { $Loading } from './Loader.styled';

const Loader = () => {
  return (
    <$Loading>
      <div></div>
      <div></div>
    </$Loading>
  );
};

export default Loader;

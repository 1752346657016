import React from 'react';

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WebsiteIcon,
  YoutubeIcon,
} from '../../assets/images/decathlon';

import { $Flexbox } from '../../css/styles';

export const DecathlonSocial = () => (
  <$Flexbox width="auto" justify="space-between" padding="16px 16px">
    <a href="https://m.facebook.com/decathlonindia/posts" target="_blank">
      <FacebookIcon />
    </a>
    <a href="https://www.instagram.com/decathlonsportsindia/?hl=en" target="_blank">
      <InstagramIcon />
    </a>
    <a href="https://www.decathlon.in" target="_blank">
      <WebsiteIcon />
    </a>
    <a href="https://twitter.com/decathlon_india?lang=en" target="_blank">
      <TwitterIcon />
    </a>
    <a href="https://www.youtube.com/user/decathlonsportindia" target="_blank">
      <YoutubeIcon />
    </a>
  </$Flexbox>
);

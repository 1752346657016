import React from 'react';

import { MarcaFacebookIcon, MarcaInstagramIcon } from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const ShilpiSocial = () => (
  <$Flexbox justify="space-around" padding="16px 0px">
    <a href="https://www.facebook.com/shilpimultiplex" target="_blank" rel="noopener noreferrer">
      <MarcaFacebookIcon style={{ width: '30px', height: '30px' }} />
    </a>
    <a
      href="https://www.instagram.com/shilpimultiplex_funcinemas/"
      target="_blank"
      rel="noopener noreferrer">
      <MarcaInstagramIcon style={{ width: '30px', height: '30px' }} />
    </a>
  </$Flexbox>
);

import React from 'react';

import { CallUsBtn, MailUsBtn, VisitUs } from '../../assets/images/social';

import { $Flexbox } from '../../css/styles';

const socialConfig = {
  superk: {
    order: ['callus', 'mailus', 'visitus'],
    callus: 'tel:+04045210677',
    mailus: 'mailto:contact@superk.in',
    visitus: 'https://www.superk.in/',
  },
};

const Calluslink = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <CallUsBtn style={{ width: '100px', height: '100px' }} />
    </a>
  );
};

const Mailuslink = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <MailUsBtn style={{ width: '100px', height: '100px' }} />
    </a>
  );
};

const Visituslink = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <VisitUs style={{ width: '100px', height: '100px' }} />
    </a>
  );
};

const GetSocialComponent = (link, socialType) => {
  switch (socialType) {
    case 'callus':
      return Calluslink(link);
    case 'mailus':
      return Mailuslink(link);
    case 'visitus':
      return Visituslink(link);
  }
};

export const SocialGroupIcons = ({ template }) => (
  <$Flexbox
    justify={socialConfig[template].order.length > 2 ? 'space-around' : 'center'}
    padding="16px 0px"
    gap={socialConfig[template].order.length > 2 ? false : '50px'}>
    {socialConfig[template].order.map((social) => {
      return GetSocialComponent(socialConfig[template][social], social);
    })}
  </$Flexbox>
);

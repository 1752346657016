import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { $Flexbox, $Button, GlobalHTMLStyle } from '../../css/styles';
import { config } from '../../config';
// import CloseIcon from '../../assets/images/close-white.svg'

const $ExplicitTopbar = styled($Flexbox)`
  background-color: #3b86ff;
  position: relative;

  p {
    margin: 0px;
    color: ${({ theme }) => theme.color.white};
  }
`;

const $BillExplicitContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  max-width: inherit;
  height: 100vh;
  background-color: hsla(0, 0%, 61.2%, 0.6);
  z-index: 10;
`;

const $BillExplicit = styled.div`
  position: fixed;
  bottom: 0px;
  width: inherit;
  max-width: inherit;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 24px 24px 0px 0px;
  z-index: 10;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0%)' : 'translateY(100%)')};
  transition: transform 0.3s ease-in-out;

  &,
  ${$ExplicitTopbar} {
    border-radius: 24px 24px 0px 0px;
  }

  * {
    font-size: 14px;
  }

  button {
    font-size: 16px;
  }
`;

const $ExplicitContent = styled($Flexbox)`
  padding: ${({ theme }) => theme.spacing.l} 0px;

  p {
    width: 80%;
    margin: 0px;
    color: #33475b;
    margin-bottom: 24px;
    text-align: center;
  }
`;

export const BillExplicit = ({ user, message, vendorId }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isContainerOpen, setIsContainerOpen] = React.useState(true);

  const handleUserClick = async (value) => {
    try {
      const res = await axios.post(`${config.host}/customer/consent`, {
        consent: value,
        user,
        vendorId,
      });
    } catch (e) {}

    handleCloseClick();
  };

  const handleCloseClick = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setIsContainerOpen(false), 400);
    }
  }, [isOpen]);

  if (!isContainerOpen) return null;

  return (
    <>
      <GlobalHTMLStyle />
      <$BillExplicitContainer>
        <$BillExplicit isOpen={isOpen}>
          <$ExplicitTopbar justify="center">
            <p>Service Explicit Permission</p>
          </$ExplicitTopbar>
          <$ExplicitContent direction="column" alignItems="center">
            <p>{message}</p>
            <div>
              <$Button
                variant="success"
                margin="0px 12px 0px 0px"
                onClick={() => handleUserClick(true)}>
                Yes
              </$Button>
              <$Button
                margin="0px 0px 0px 12px"
                variant="primary-outline"
                onClick={() => handleUserClick(false)}>
                No
              </$Button>
            </div>
          </$ExplicitContent>
        </$BillExplicit>
      </$BillExplicitContainer>
    </>
  );
};

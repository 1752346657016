import React from 'react';

import {
  MarcaFacebookIcon,
  MarcaInstagramIcon,
  WebIcon,
  YoutubeIcon,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const EemosSocial = () => (
  <$Flexbox justify="space-around" padding="16px 0px" alignItems="center">
    <a
      href="https://www.facebook.com/eemossalonandacademy"
      target="_blank"
      rel="noopener noreferrer">
      <MarcaFacebookIcon style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.instagram.com/eemosbeautysalon/" target="_blank" rel="noopener noreferrer">
      <MarcaInstagramIcon style={{ width: '30px', height: '30px' }} />
    </a>
    <a
      href="https://www.youtube.com/channel/UC7viC73nnuzQsiiWOj8YXbQ"
      target="_blank"
      rel="noopener noreferrer">
      <YoutubeIcon />
    </a>
    <a href="https://www.eemos.in/" target="_blank" rel="noopener noreferrer">
      <WebIcon style={{ width: '33px', height: '30px' }} />
    </a>
  </$Flexbox>
);

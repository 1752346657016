import React from 'react';

import {
  $OTPViewBlock,
  $BrandLogoBlock,
  $BrandLogo,
  $OTPBlock,
  $Instructions,
  $ButtonBlock,
  $Body,
} from './styled';

const OTPView = ({ otp, brandLogo, onViewBillClick }) => {
  if (!otp) return <>invalid request</>;

  return (
    <$OTPViewBlock>
      <$BrandLogoBlock>
        <$BrandLogo>
          <img src={brandLogo} alt="brand-logo" />
        </$BrandLogo>
      </$BrandLogoBlock>

      <$Body>
        <$OTPBlock>
          {otp
            ?.toString()
            ?.split('')
            .map((value, index) => (
              <li key={index}>
                <span>{value}</span>
                {index === otp?.toString().length - 1 ? null : <span />}
              </li>
            ))}
        </$OTPBlock>

        <$Instructions>
          <p>Provide the OTP to the cashier for order verification</p>
          <p>Once verified click below to view bill</p>
        </$Instructions>

        <$ButtonBlock>
          <button onClick={onViewBillClick}>View Bill</button>
        </$ButtonBlock>
      </$Body>
    </$OTPViewBlock>
  );
};

export default OTPView;

import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import RZPBMLogo from '../../assets/images/logos/rzpbm-logo-Light.svg';
import { PrimaryButton } from 'css/styles/common';
import axios from 'axios';
import { config } from 'config';
import { useToasts } from 'components/Toast';

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: 100%;
  padding-top: 20vh;
`;

const PageTitle = styled.h1`
  font-size: 1.75em;
  text-align: center;
  font-family: 'Clearface bold', serif;
  font-weight: 800;
  color: #043140;

  span {
    color: #12b9f3;
    font-family: 'Clearface bold', serif;
    font-weight: 800;
  }
`;
const Input = styled.input`
  border-radius: 20px;
  padding: 10px 20px;
  width: 100%;
  border: none;
  margin-bottom: 15px;
  box-sizing: border-box;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const CustomInput = styled(Input)`
  &&& {
    border: 1px solid black;
    border-color: ${(props) => (props.error ? 'hsl(0 100% 60%)' : 'hsl(0 0% 90%)')};
    border-radius: 6px;
    outline: none;
    padding: 10px;
    margin-bottom: 0;
    &:hover,
    &:focus {
      border: 1px solid #072654;
    }
  }
`;

const RzpStyleButton = styled(PrimaryButton)`
  background-color: #072654;
  border-color: #072654;
  width: 100%;
  margin-top: 20px;
`;

const RzpLabel = styled.label`
  color: #072654;
  margin-bottom: 4px;
  display: inline-block;
`;
const FormBox = styled.div`
  padding: 5px;
  margin-top: 30px;
  width: 250px;
`;
const ErrorMessage = ({ text, type, ...rest }) => (
  <span
    {...rest}
    style={{
      color: 'hsl(0 100% 60%)',
      display: type === 'empty' ? 'none' : 'block',
      fontSize: '0.8em',
      marginTop: '4px',
      marginBlock: 'auto',
    }}>
    {text}
  </span>
);

const Auth = ({ fetchBillDetails, billUid }) => {
  const [userIdentifier, setUserIdentifier] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { toast } = useToasts();
  const onSubmit = () => {
    if (!userIdentifier) {
      setError('Please enter a valid phone number or email');
      return;
    }

    if (userIdentifier.length > 254) {
      setError('Input too long');
      return;
    }
    setError('');
    setIsSubmitting(true);
    axios
      .post(`${config.host}/auth/generate`, {
        fieldName: 'user',
        userIdentifier,
        billUid,
      })
      .then(() => {
        setIsSubmitting(false);
        fetchBillDetails();
      })
      .catch((err) => {
        toast(err?.response?.data?.message || 'Something went wrong', 'error');
        setIsSubmitting(false);
      });
  };

  return (
    <Fragment>
      <FlexBox>
        <img src={RZPBMLogo} width="250px" style={{ textAlign: 'center' }} />
        <PageTitle>
          Hello!
          <br />
          View your digital bill
        </PageTitle>
        <FormBox>
          <RzpLabel htmlFor="phone-number">Phone Number / Email</RzpLabel>
          <CustomInput
            name="phone-number"
            placeholder="Enter your phone number or email"
            onChange={(e) => {
              setError('');
              setUserIdentifier(e.target.value);
            }}
            value={userIdentifier}
            error={error}
          />
          {error && <ErrorMessage text={error} />}
          <RzpStyleButton onClick={onSubmit} disabled={isSubmitting}>
            Submit
          </RzpStyleButton>
        </FormBox>
        {/* <SubmitButton onClick={onSubmit} disabled={isSubmitting}> */}
        {/*   Send OTP */}
        {/* </SubmitButton> */}
      </FlexBox>
    </Fragment>
  );
};

export default Auth;

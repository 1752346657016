import React from 'react';
import styled from 'styled-components';

const $SuspenseLoadingText = styled.p`
  margin: 0;
  position: absolute;
  top: 0px;
`;

export const SuspenseLoading = () => <$SuspenseLoadingText>Loading</$SuspenseLoadingText>;

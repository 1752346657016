import React from 'react';

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WebsiteIcon,
} from '../../assets/images/decathlon';

import { $Flexbox } from '../../css/styles';

export const HastimalManikchandSocial = () => (
  <$Flexbox width="auto" justify="space-around" padding="16px 16px">
    <a href="https://facebook.com/hastimalmanikchand" target="_blank">
      <FacebookIcon />
    </a>
    <a href="https://www.instagram.com/hmkhopoli/" target="_blank">
      <InstagramIcon />
    </a>
    <a href="https://www.hastimalmanikchand.com" target="_blank">
      <WebsiteIcon />
    </a>
    <a href="https://twitter.com/hmkhopoli" target="_blank">
      <TwitterIcon />
    </a>
  </$Flexbox>
);

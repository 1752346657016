import React from 'react';
import {
  BacktoBillBestIcon,
  ThankYouBestIcon,
  CheckEmptyBestIcon,
  CheckFilledBestIcon,
  RadioEmptyBestIcon,
  RadioFilledBestIcon,
  CalendarBestIcon,
} from '../../../assets/images/best-seller';
import {
  BacktoBillNykaaIcon,
  CalendarNykaaIcon,
  CheckEmptyNykaaIcon,
  CheckFilledNykaaIcon,
  RadioEmptyNykaaIcon,
  RadioFilledNykaaIcon,
  ThankYouNykaaIcon,
} from '../../../assets/images/nykaa';
import {
  BacktoBillMcDIcon,
  CalendarMcDIcon,
  CheckEmptyMcDIcon,
  CheckFilledMcDIcon,
  RadioEmptyMcDIcon,
  RadioFilledMcDIcon,
  ThankYouMcDIcon,
} from '../../../assets/images/mcd';

const Icons = ({ isBestSeller, isBaggitFeedback, isMODFeedback, isMcDFeedback, isNykaaStore }) => {
  const getBackIcon = () => {
    if (isBestSeller || isBaggitFeedback || isMODFeedback) return <BacktoBillBestIcon />;
    if (isNykaaStore) return <BacktoBillNykaaIcon />;
    if (isMcDFeedback) return <BacktoBillMcDIcon />;
  };

  const getThankYouIcon = () => {
    if (isBestSeller || isBaggitFeedback || isMODFeedback) return <ThankYouBestIcon />;
    if (isNykaaStore) return <ThankYouNykaaIcon />;
    if (isMcDFeedback) return <ThankYouMcDIcon />;
  };

  const getRadioFilledIcon = () => {
    if (isBestSeller || isBaggitFeedback || isMODFeedback) return <RadioFilledBestIcon />;
    if (isNykaaStore) return <RadioFilledNykaaIcon />;
    if (isMcDFeedback) return <RadioFilledMcDIcon />;
  };

  const getRadioEmptyIcon = () => {
    if (isBestSeller || isBaggitFeedback || isMODFeedback) return <RadioEmptyBestIcon />;
    if (isNykaaStore) return <RadioEmptyNykaaIcon />;
    if (isMcDFeedback) return <RadioEmptyMcDIcon />;
  };

  const getCheckEmptyIcon = () => {
    if (isBestSeller || isBaggitFeedback || isMODFeedback) return <CheckEmptyBestIcon />;
    if (isNykaaStore) return <CheckEmptyNykaaIcon />;
    if (isMcDFeedback) return <CheckEmptyMcDIcon />;
  };

  const getCheckFilledIcon = () => {
    if (isBestSeller || isBaggitFeedback || isMODFeedback) return <CheckFilledBestIcon />;
    if (isNykaaStore) return <CheckFilledNykaaIcon />;
    if (isMcDFeedback) return <CheckFilledMcDIcon />;
  };

  const getCalendarIcon = () => {
    if (isBestSeller || isBaggitFeedback || isMODFeedback) return <CalendarBestIcon />;
    if (isNykaaStore) return <CalendarNykaaIcon />;
    if (isMcDFeedback) return <CalendarMcDIcon />;
  };
  return {
    getBackIcon,
    getThankYouIcon,
    getRadioFilledIcon,
    getRadioEmptyIcon,
    getCheckEmptyIcon,
    getCheckFilledIcon,
    getCalendarIcon,
  };
};

export default Icons;

import React from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import Axios from 'axios';
import { IntlProvider } from 'react-intl';

import { $Flexbox } from '../../css/styles';
import { NykaaDownloadBillPdfTemplate } from '../templates/NykaaEcom';
import { config } from '../../config';

const $DownloadBillButton = styled.button`
  color: ${({ theme }) => theme.color.nykaa};
  background-color: #fff;
  border: none;
  text-decoration: underline;
  font-size: 14px;
  margin: 14px auto;
  cursor: pointer;

  &:disabled {
    color: #aaa;
  }
`;
export const DownloadBill = React.forwardRef(({ customData, handleDownloadBillClick }, ref) => {
  const [isDownloadingBill, setIsDownloadingBill] = React.useState(false);

  const getImage = async () => {
    setIsDownloadingBill(true);
    const html = renderToString(
      <IntlProvider locale="en">
        <NykaaDownloadBillPdfTemplate customData={customData} />
      </IntlProvider>,
    );
    try {
      const res = await Axios.post(
        `${config.host}/pdf`,
        { html },
        {
          responseType: 'arraybuffer',
        },
      );

      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));

      let link = document.createElement('a');
      link.download = 'Your-bill.pdf';
      link.href = url;
      link.click();
    } catch (err) {
      console.log('Error:--', err);
    }

    setIsDownloadingBill(false);
  };

  return (
    <$Flexbox justify="center" padding="0">
      <$DownloadBillButton onClick={handleDownloadBillClick} disabled={isDownloadingBill}>
        Download Bill
      </$DownloadBillButton>
    </$Flexbox>
  );
});

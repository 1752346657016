import { format } from 'date-fns';

export const getDateAndTime = (dateInMs, givenFormat) => {
  if (!dateInMs) return {};

  const d = new Date(dateInMs);
  let newDate;
  let newTime;
  let formattedDateAndTime;
  let twelveHourTime;
  let ddMMYYYY;

  if (givenFormat) {
    formattedDateAndTime = format(d, givenFormat);
  } else {
    const hours = d?.getHours();
    const minutes = ('0' + d?.getMinutes()).slice(-2);
    const fullYear = d?.getFullYear();
    const month = ('0' + (d?.getMonth() + 1)).slice(-2);
    const date = ('0' + d?.getDate()).slice(-2);
    const seconds = ('0' + d?.getSeconds()).slice(-2);

    newDate = `${fullYear}-${month}-${date}`;
    newTime = `${('0' + hours).slice(-2)}:${minutes}`;
    twelveHourTime = `${('0' + (hours > 12 ? hours - 12 : hours)).slice(
      -2,
    )}:${('0' + d?.getMinutes()).slice(-2)}:${seconds} ${hours >= 12 ? 'PM' : 'AM'}`;

    ddMMYYYY = `${date}-${month}-${fullYear}`;
  }
  return {
    date: newDate,
    time: newTime,
    formattedDateAndTime,
    twelveHourTime,
    ddMMYYYY,
  };
};

import React from 'react';
import { $Flexbox } from '../../css/styles';
import { FacebookIcon, YoutubeIcon } from '../../assets/images/decathlon';
import { NykaaSupportIcon, NykaaWebstoreIcon } from '../../assets/images/social';

import NykaaInstagramImageIcon from '../../assets/images/social/nykaa-instagram.png';

export const NykaaSocial = () => (
  <$Flexbox justify="space-around">
    <a href="https://www.facebook.com/MyNykaa" target="_blank">
      <FacebookIcon style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.instagram.com/mynykaa" target="_blank">
      <img src={NykaaInstagramImageIcon} style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.youtube.com/channel/UCoaH2UtB1PsV7av17woV1BA" target="_blank">
      <YoutubeIcon style={{ width: '30px', height: '30px' }} />
    </a>
  </$Flexbox>
);
export const NykaaOnline = () => (
  <$Flexbox
    justify="space-around"
    style={{ maxWidth: '250px', margin: '0px auto' }}
    padding={'16px 0px'}>
    <$Flexbox
      as="a"
      href="tel:18002674444"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none' }}>
      <NykaaSupportIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>24*7 Toll Free</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://www.nykaa.com/"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none' }}>
      <NykaaWebstoreIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Webstore</p>
    </$Flexbox>
  </$Flexbox>
);

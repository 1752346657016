import React from 'react';
import Axios from 'axios';
import { createGlobalStyle } from 'styled-components';
import { Document, pdfjs, Page } from 'react-pdf';

import { config } from '../../config';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { SecondaryButton } from '../../css/styles';
import { BillImage } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = createGlobalStyle`
    .react-pdf__Document{
        overflow: hidden;
    }
`;

const Error = ({ billImageUrl, handleDownloadClick }) => (
  <div style={{ textAlign: 'center' }}>
    {/* <p>Failed to load PDF, Download your bill</p> */}
    <BillImage src={billImageUrl} />
    <SecondaryButton onClick={handleDownloadClick}>Download your bill</SecondaryButton>
  </div>
);

const Pdf = ({ url, size, adjustHeight, billUid, isRRGold = false }) => {
  const [totalPages, setTotalPages] = React.useState();

  const pdfLoaded = ({ numPages }) => {
    setTotalPages(numPages);

    setTimeout(() => {
      adjustHeight && adjustHeight(true);
    }, 500);
  };

  const handleDownloadClick = async (_) => {
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.setAttribute('download', 'download');
    element.click();
  };

  const handleError = (err) => {
    console.log('Pdf loading error::', err);
  };

  return (
    <>
      <Container />
      <Document
        file={url}
        onLoadSuccess={pdfLoaded}
        onLoadError={handleError}
        error={
          isRRGold ? (
            <Error billImageUrl={url} handleDownloadClick={handleDownloadClick} />
          ) : (
            'Failed to load PDF file.'
          )
        }>
        {[...new Array(totalPages).fill(0)].map((item, i) => (
          <Page pageIndex={i} width={size || 380} />
        ))}
      </Document>
    </>
  );
};

export default Pdf;

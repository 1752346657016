import React from 'react';

import {
  MarcaFacebookIcon,
  MarcaInstagramIcon,
  MarcaTwitterIcon,
  MarcaWebsiteIcon,
  MarcaWhatsappIcon,
  MarcaSupportIcon,
  MarcaEmailIcon,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const Le15Social = () => (
  <$Flexbox justify="center">
    <a
      href="https://www.facebook.com/Le15India/"
      target="_blank"
      style={{ marginRight: '48px' }}
      rel="noopener noreferrer">
      <MarcaFacebookIcon style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.instagram.com/le15india/?hl=en" target="_blank" rel="noopener noreferrer">
      <MarcaInstagramIcon style={{ width: '30px', height: '30px' }} />
    </a>
    <a
      href="https://twitter.com/le15patisserie?lang=en"
      target="_blank"
      style={{ marginLeft: '48px' }}
      rel="noopener noreferrer">
      <MarcaTwitterIcon style={{ width: '30px', height: '30px' }} />
    </a>
  </$Flexbox>
);

export const Le15Online = () => (
  <$Flexbox justify="space-around">
    <$Flexbox
      as="a"
      href="tel:+918591621649"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaSupportIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Call Us</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="mailto:accountsteam@le15.com"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaEmailIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Email Us</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://www.le15.com"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaWebsiteIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Website</p>
    </$Flexbox>
  </$Flexbox>
);

import React from 'react';
import { $SemiBold } from '../css/styles';

export const SemiBoldTag = ({ label, value, paraStyle, htmlTag = 'p', isValueBold, width }) => {
  const CustomTag = htmlTag;

  const getValue = () => {
    if (isValueBold) return <$SemiBold>{value}</$SemiBold>;
    return value;
  };

  if (value !== undefined && value !== null && value !== '')
    return (
      <CustomTag style={{ ...paraStyle, width }}>
        <$SemiBold>{label} :</$SemiBold> {getValue()}
      </CustomTag>
    );

  return null;
};

import React from 'react';

import {
  MarcaFacebookIcon,
  MarcaInstagramIcon,
  MarcaTwitterIcon,
  MarcaWebsiteIcon,
  MarcaWhatsappIcon,
  MarcaSupportIcon,
  MarcaEmailIcon,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const MarcaSocial = () => (
  <$Flexbox justify="center">
    <a
      href="https://www.facebook.com/marcadisati/"
      target="_blank"
      style={{ marginRight: '32px' }}
      rel="noopener noreferrer">
      <MarcaFacebookIcon style={{ width: '30px', height: '30px' }} />
    </a>
    <a
      href="https://www.instagram.com/marcadisati/?hl=en"
      target="_blank"
      style={{ marginLeft: '32px' }}
      rel="noopener noreferrer">
      <MarcaInstagramIcon style={{ width: '30px', height: '30px' }} />
    </a>
  </$Flexbox>
);

export const MarcaOnline = () => (
  <$Flexbox justify="space-around">
    <$Flexbox
      as="a"
      href="tel:+918047187709"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaSupportIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Call Us</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="mailto:care@marcadisati.com"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaEmailIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Email Us</p>
    </$Flexbox>
    <$Flexbox
      as="a"
      href="https://marcadisati.com/"
      target="_blank"
      direction="column"
      justify="center"
      alignItems="center"
      style={{ textDecoration: 'none', width: 'auto' }}
      padding="0px"
      rel="noopener noreferrer">
      <MarcaWebsiteIcon style={{ width: '40px', height: '40px' }} />
      <p style={{ fontSize: '10px', color: '#000' }}>Website</p>
    </$Flexbox>
  </$Flexbox>
);

import React from 'react';

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WebsiteIcon,
  YoutubeIcon,
} from '../../assets/images/decathlon';

import { $Flexbox } from '../../css/styles';

export const CCDSocial = () => (
  <$Flexbox width="auto" justify="space-between" padding="16px 16px">
    <a href="https://www.facebook.com/cafecoffeeday" target="_blank">
      <FacebookIcon />
    </a>
    <a href="https://www.instagram.com/CafeCoffeeday/" target="_blank">
      <InstagramIcon />
    </a>
    <a href="https://shop.cafecoffeeday.com/" target="_blank">
      <WebsiteIcon />
    </a>
    <a href="https://twitter.com/CafeCoffeeDay" target="_blank">
      <TwitterIcon />
    </a>
    <a href="https://www.youtube.com/user/cafecoffeeday" target="_blank">
      <YoutubeIcon />
    </a>
  </$Flexbox>
);

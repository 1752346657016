import styled from 'styled-components';

import { BillMaxWidth } from 'components/constants';
import otpBackdrop from 'assets/images/otp-backdrop.jpeg';

export const $OTPViewBlock = styled.div`
  max-width: ${BillMaxWidth};
  margin: 0 auto;
  position: relative;
  z-index: 2;
  max-height: 100vh;
  overflow: hidden;
`;

export const $BrandLogoBlock = styled.div`
  background-color: #fff;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const $BrandLogo = styled.div`
  max-width: 150px;
  max-height: 150px;
  margin: auto;
  img {
    width: 100%;
  }
`;

export const $OTPBlock = styled.ul`
  padding: 64px 0 32px;
  list-style: none;
  display: flex;
  justify-content: center;
  li {
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      margin-top: 32px;
      font-size: 40px;
      padding: 16px;
      color: #01abf0;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 8px;
      box-shadow:
        -2px 6px 10px #cecece,
        2px -6px 10px #f2f2f2;
      font-weight: 900;
    }
    span:nth-child(2) {
      width: 10px;
      height: 10px;
      background-color: #01abf0;
      border-radius: 50%;
      margin: 0 16px 8px;
      box-shadow:
        -2px 6px 10px #cecece,
        2px -6px 10px #f2f2f2;
    }
  }
`;
export const $Instructions = styled.div`
  padding: 16px 8px;
  text-align: center;
  font-size: 16px;
`;

export const $ButtonBlock = styled.div`
  margin-top: 32px;
  padding-top: 8px;
  text-align: center;
  button {
    width: 200px;
    height: 50px;
    display: inline-block;
    color: #fff;
    background-color: #01abf0;
    font-size: 18px;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow:
      -2px 6px 10px #cecece,
      2px -6px 10px #f2f2f2;
  }
`;

export const $Body = styled.div`
  height: 100vh;
  background: URL(${otpBackdrop});
  background-size: cover;
`;

import React from 'react';

import {
  MarcaFacebookIcon,
  MarcaInstagramIcon,
  YoutubeIcon,
  MarcaTwitterIcon,
  LinkedInIcon,
  SnapchatIcon,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

const social = () => (
  <>
    <$Flexbox justify="space-between" width={'auto'} padding="16px 16px">
      <a
        href="http://www.facebook.com/Cossouq/"
        target="_blank"
        // style={{ marginRight: "80px" }}
        rel="noopener noreferrer">
        <MarcaFacebookIcon style={{ width: '30px', height: '30px' }} />
      </a>
      <a
        href="https://www.instagram.com/cossouq"
        target="_blank"
        // style={{ marginRight: "80px" }}
        rel="noopener noreferrer">
        <MarcaInstagramIcon style={{ width: '30px', height: '30px' }} />
      </a>
      <a
        href="https://www.youtube.com/channel/UCi3SdcbVLMiiXyPQ9IiQBTg"
        target="_blank"
        // style={{ marginRight: "80px" }}
        rel="noopener noreferrer">
        <YoutubeIcon style={{ width: '30px', height: '30px' }} />
      </a>
    </$Flexbox>
    <$Flexbox justify="space-between" width={'auto'} padding="16px 16px">
      <a href="http://www.twitter.com/cossouq" target="_blank" rel="noopener noreferrer">
        <MarcaTwitterIcon style={{ width: '30px', height: '30px' }} />
      </a>
      <a
        href="https://www.linkedin.com/company/cossouq/"
        target="_blank"
        // style={{ marginRight: "80px" }}
        rel="noopener noreferrer">
        <LinkedInIcon style={{ width: '30px', height: '30px' }} />
      </a>
      <a href="https://www.snapchat.com/add/cossouq/" target="_blank" rel="noopener noreferrer">
        <SnapchatIcon style={{ width: '30px', height: '30px' }} />
      </a>
    </$Flexbox>
  </>
);
export default social;

import React, { Component } from 'react';

import {
  StyledFooter,
  BkDownloadButton,
  BestSellerDownloadButton,
  HdfcDownloadButton,
  MahavirVisitButton,
  GoColorsVisitButton,
  Ftx25Btn,
  UpgradeToText,
} from './styles';
import CinepolisFooter from 'assets/images/cinepolisfooter.png';
import CityofPatnaLogo from '../../assets/images/gadchiroli/city-of-patna-logo.png';

class NewFooter extends Component {
  constructor(props) {
    super(props);

    this.handleBkDownloadClick = this.handleBkDownloadClick.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleRzpFtx25Click = this.handleRzpFtx25Click.bind(this);
  }

  handleRzpFtx25Click() {
    let win = window.open('https://www.youtube.com/watch?v=Uq9MKaBpKf8', '_blank');
    win.focus();
  }
  handleBkDownloadClick() {
    var win = window.open('http://onelink.to/bkil', '_blank');
    win.focus();
  }

  handleDownloadClick() {
    var win = window.open(this.props.downloadAppLink(), '_blank');
    win.focus();
  }

  openDownloadLink() {
    const isAndroid = /android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPod|iPad/i.test(navigator.userAgent);

    if (isAndroid) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.snapwork.hdfc&hl=en_IN&gl=US&pli=1',
      );
    } else if (isIOS) {
      window.open('https://apps.apple.com/us/app/hdfc-bank-mobilebanking/id515891771');
    } else {
      window.open(
        'https://www.hdfcbank.com/personal/ways-to-bank/mobilebanking/hdfc-bank-mobilebanking-app',
      );
    }
  }
  handleVisitStoreClick() {
    var win = window.open('https://www.mahavirhomestore.com ', '_blank');
    win.focus();
  }

  handleVisitStoreClickGoColors() {
    var win = window.open('https://gocolors.com/ ', '_blank');
    win.focus();
  }

  render() {
    const CustomTag = this.props.showDownloadAppText || this.props.customText ? 'button' : 'a';
    const clickLink =
      this.props.showDownloadAppText || this.props.customText
        ? { onClick: () => this.handleDownloadClick() }
        : { onClick: () => this.props.handleDownloadBillClick() };

    return (
      <>
        <div
          style={
            this.props.isNyCinemas
              ? { width: '100%' }
              : { height: this.props.isRzpFtx25 ? '85px' : '70px', width: '100%' }
          }
        />
        <StyledFooter
          isNewFooter
          isBK={this.props.isBK}
          btnColor={this.props.btnColor}
          position={this.props.isNyCinemas ? 'relative' : 'fixed'}
          backgroundcolor={
            this.props.isBewakoof ? '#FDD835' : this.props.isManyavar ? '#4C2008' : ''
          }
          className={`bill-footer media-no-print`}>
          <div
            style={this.props.makeLogoBig ? { width: '100px' } : {}}
            className={
              this.props.isHdfChargeSlip
                ? 'billme-logo-hdfc'
                : this.props.isBewakoof
                  ? 'billme-logo-bewakoof'
                  : this.props.isGoColors
                    ? 'billme-logo-goColors'
                    : 'billme-logo'
            }>
            {!this.props.isBK &&
              !this.props.isCityOfPatna &&
              !this.props.isGadchiroli &&
              !this.props.isCinepolisGroup && <img src={this.props.brandLogo} />}
            {this.props.isGadchiroli && (
              <img src="https://assets.billme.co.in/gadchiroli-assets/Dist-of%20gadricholi.webp" />
            )}
            {this.props.isCityOfPatna && <img src={CityofPatnaLogo} style={{ width: '150px' }} />}
            {this.props.isCinepolisGroup && (
              <img src={CinepolisFooter} style={{ width: '150px' }} />
            )}
          </div>
          {this.props.isRzpFtx25 ? (
            <UpgradeToText>
              UPGRADE TO <span> SMART BILLS</span>
            </UpgradeToText>
          ) : null}

          {this.props.isBestSeller && (
            <a
              className="meta-text"
              href="https://www.jackjones.in/"
              target="_blank"
              rel="noreferrer noopener">
              <i>Shop Online</i>
            </a>
          )}
          {this.props.isBK && <span className="bk">FOR BEST OFFERS!</span>}
          {this.props.showMetaText && (
            <span className="meta-text">
              <i>{this.props.showMetaText}</i>
            </span>
          )}
          {!this.props.isDecathlon && !this.props.hideFooterButton && (
            <div
              className="footer-body"
              {...(this.props.isCinepolisGroup ? { style: { gridColumn: '2/6' } } : {})}>
              {this.props.isRzpFtx25 ? (
                <Ftx25Btn onClick={this.handleRzpFtx25Click}>Know More</Ftx25Btn>
              ) : this.props.isBK ? (
                <BkDownloadButton className="download-btn" onClick={this.handleBkDownloadClick}>
                  DOWNLOAD THE BK APP
                </BkDownloadButton>
              ) : this.props.isBestSeller ? (
                <BestSellerDownloadButton
                  className="download-btn"
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.bestseller.jackjones&hl=en_IN&gl=US',
                    )
                  }>
                  Download App
                </BestSellerDownloadButton>
              ) : this.props.isHdfChargeSlip ? (
                <HdfcDownloadButton className="download-btn" onClick={this.openDownloadLink}>
                  Download App
                </HdfcDownloadButton>
              ) : this.props.isMahavirStore ? (
                <MahavirVisitButton className="download-btn" onClick={this.handleVisitStoreClick}>
                  Visit Website
                </MahavirVisitButton>
              ) : this.props.isGoColors ? (
                <GoColorsVisitButton
                  className="download-btn"
                  onClick={this.handleVisitStoreClickGoColors}>
                  Visit Website
                </GoColorsVisitButton>
              ) : (
                <CustomTag
                  className="download-btn"
                  download="Yourbill"
                  {...clickLink}
                  {...(this.props.isCinepolisGroup ? { style: { padding: '10px 15px' } } : {})}
                  {...(this.props.isCinepolisTicket
                    ? {
                        style: {
                          padding: '7px 15px',
                          fontWeight: 600,
                          fontFamily: 'Montserrat, sans-serif',
                        },
                      }
                    : {})}
                  {...(this.props.isBewakoof
                    ? { style: { fontSize: '16px', backgroundColor: '#000000' } }
                    : this.props.isManyavar
                      ? { style: { fontSize: '16px', backgroundColor: '#F46F23' } }
                      : {})}>
                  {this.props.showDownloadAppText && !this.props.customText && 'Download App'}

                  {this.props.customText || ''}

                  {!this.props.customText && !this.props.showDownloadAppText && 'Download Bill'}
                </CustomTag>
              )}
            </div>
          )}
        </StyledFooter>
      </>
    );
  }
}

export default NewFooter;
